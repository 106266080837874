import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { AnimationClasses, Button, Icons, Text } from '@zaber/react-library';
import { useSelector } from 'react-redux';
import { NavigationBar } from '../navigation_bar';
import { selectCreatingSimulations, selectIsBuildingChain, selectPrivateSimulations, } from '../virtual_device/selectors';
import NoDevicesFoundImage from '../assets/no_vd_in_list.svg';
import { Footer } from '../components/Footer';
import { PageUrls } from '../urls';
import { useActions } from '../utils';
import { virtualDeviceActionDefinitions } from '../virtual_device';
import { PageTitle } from '../components/PageTitle';
import { Survey } from '../components/Survey';
import { useLoadPrivateSimulations, useLoadSimulationProducts } from '../virtual_device/effects';
import { PrivateVirtualDeviceList } from './PrivateVirtualDeviceList';
import { DeviceManagerErrors } from './DeviceManagerErrors';
export const VirtualDeviceManager = () => {
    var _a;
    const actions = useActions(virtualDeviceActionDefinitions);
    const creatingSimulations = useSelector(selectCreatingSimulations);
    const isBuildingChain = useSelector(selectIsBuildingChain);
    const privateSimulations = useSelector(selectPrivateSimulations);
    const privateSimulationCount = (_a = privateSimulations === null || privateSimulations === void 0 ? void 0 : privateSimulations.length) !== null && _a !== void 0 ? _a : 0;
    useLoadSimulationProducts();
    const loadingSimulationsState = useLoadPrivateSimulations();
    const loadingSimulations = (loadingSimulationsState === null || loadingSimulationsState === void 0 ? void 0 : loadingSimulationsState.state) === 'loading';
    useEffect(() => {
        actions.startMonitoring();
        return () => {
            actions.resetSimulationCreationError();
            actions.stopMonitoring();
        };
    }, []);
    return (_jsxs("div", { className: "virtual-device-manager page", children: [_jsx(PageTitle, { children: "My Virtual Devices" }), _jsx(NavigationBar, {}), _jsxs("div", { className: "content", children: [_jsxs("div", { className: "page-title-container", children: [_jsxs("div", { children: [_jsx(Text, { className: "page-title", t: Text.Type.H3, e: Text.Emphasis.Light, children: "My Virtual Devices" }), !loadingSimulations &&
                                        _jsx(Button, { className: "create-chain", disabled: isBuildingChain, onClick: () => {
                                                actions.chainBuilderStart();
                                                actions.chainBuilderAddDevice();
                                            }, children: "Create Device Chain" })] }), _jsx("div", { className: "padding", children: "\u00A0" }), _jsx(Survey, { emphasis: Text.Emphasis.Light })] }), loadingSimulations && _jsxs("div", { className: "loading-devices", children: [_jsx(Icons.Gear, { className: AnimationClasses.Rotation }), _jsx(Text, { t: Text.Type.H3, e: Text.Emphasis.Light, children: "Loading..." })] }), !loadingSimulations && privateSimulationCount === 0 &&
                        creatingSimulations.length === 0 && !isBuildingChain && _jsxs("div", { className: "no-devices-found", children: [_jsx(NoDevicesFoundImage, { className: "no-devices-found-image" }), _jsx(Text, { t: Text.Type.H4, children: "You currently have no virtual devices." })] }), !loadingSimulations && (privateSimulationCount > 0 || creatingSimulations.length > 0 || isBuildingChain) &&
                        _jsx("div", { className: "devices-found", children: _jsx(PrivateVirtualDeviceList, {}) }), _jsx(DeviceManagerErrors, {})] }), _jsx(Footer, { releaseNotesUrl: PageUrls.VirtualDeviceReleaseNotes })] }));
};
