import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { Text, Icons, AnimationClasses } from '@zaber/react-library';
export const IconMessage = ({ icon, rotatingIcon = false, color, small = false, className, children }) => {
    const IconComponent = Icons[icon];
    const containerClassNames = classNames('icon-message-container', icon, className, {
        small,
    });
    return (_jsxs("div", { className: containerClassNames, children: [_jsx(IconComponent, { className: rotatingIcon ? AnimationClasses.Rotation : undefined, color: color }), _jsx("div", { className: "message", children: _jsx(Text, { t: small ? Text.Type.Helper : undefined, children: children }) })] }));
};
