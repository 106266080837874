import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import moment from 'moment';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { AnimationClasses, Icons, Text } from '@zaber/react-library';
import { useSelector } from 'react-redux';
import { Storage } from '@zaber/app-components/lib/storage';
import { FeedbackLink } from '../feedback';
import { SimulationState } from '../virtual_device_api/types';
import { VirtualDeviceOnOffToggle } from '../virtual_device/VirtualDeviceOnOffToggle';
import { Terminal } from '../terminal';
import { TimeLeft } from '../components/TimeLeft';
import { getContainer } from '../container';
import { selectUserIsLoggedIn } from '../user';
import { selectTurnOnRetryInfo } from '../virtual_device/selectors';
import { useActions } from '../utils';
import { actions as actionDefinitions } from './actions';
import { selectCurrentSimulation, selectSelectedDevice, selectSimulationSetupError, selectSimulationSetupInProgress } from './selectors';
import { ViewerMessageOverlay } from './ViewerMessageOverlay';
import { ViewerConnectionInstructions } from './connection_instructions/ViewerConnectionInstructions';
import { ViewerControlsAxis } from './ViewerControlsAxis';
import { FIRST_TIME_HELP_KEY } from './types';
const SidebarButton = ({ icon, type, currentSelectedType, onClick, title }) => {
    const IconComponent = Icons[icon];
    return (_jsx(IconComponent, { activated: currentSelectedType === type, appearsClickable: true, onClick: () => onClick === null || onClick === void 0 ? void 0 : onClick(currentSelectedType === type ? null : type), interactionColor: "white", title: title }));
};
const FeedbackIcon = Icons.Feedback;
function getComponentIndexForAxis(simulation, axisKey) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    let axisIndex = (_b = (_a = simulation.axes) === null || _a === void 0 ? void 0 : _a.indexOf(axisKey)) !== null && _b !== void 0 ? _b : -1;
    if (axisIndex === -1) {
        return null;
    }
    for (let componentIndex = 0; componentIndex < simulation.components.length; componentIndex++) {
        const component = simulation.components[componentIndex];
        if (axisIndex < ((_e = (_d = (_c = component.modelManifest) === null || _c === void 0 ? void 0 : _c.axes) === null || _d === void 0 ? void 0 : _d.length) !== null && _e !== void 0 ? _e : 0)) {
            return componentIndex;
        }
        axisIndex -= (_h = (_g = (_f = component.modelManifest) === null || _f === void 0 ? void 0 : _f.axes) === null || _g === void 0 ? void 0 : _g.length) !== null && _h !== void 0 ? _h : 0;
    }
    return null;
}
export const ViewerControlsOverlay = ({ onCameraReset }) => {
    var _a, _b;
    const actions = useActions(actionDefinitions);
    const [selectedSidebarButton, setSelectedSidebarButton] = useState(null);
    useLayoutEffect(() => {
        var _a;
        const storage = getContainer().get(Storage);
        const show = (_a = storage.load(FIRST_TIME_HELP_KEY)) !== null && _a !== void 0 ? _a : true;
        if (show) {
            setSelectedSidebarButton('connection-instructions');
            storage.save(FIRST_TIME_HELP_KEY, false);
        }
    }, []);
    const currentSimulation = useSelector(selectCurrentSimulation);
    const setupInProgress = useSelector(selectSimulationSetupInProgress);
    const setupError = useSelector(selectSimulationSetupError);
    const loggedIn = useSelector(selectUserIsLoggedIn);
    const isRetryingTurnOn = useSelector(selectTurnOnRetryInfo).map(info => info.id).includes((_a = currentSimulation === null || currentSimulation === void 0 ? void 0 : currentSimulation.id) !== null && _a !== void 0 ? _a : '');
    const selectedModel = useSelector(selectSelectedDevice);
    const [expired, setExpired] = useState(false);
    useEffect(() => {
        setExpired(false);
    }, [currentSimulation === null || currentSimulation === void 0 ? void 0 : currentSimulation.expires]);
    if (!currentSimulation) {
        return _jsx(ViewerMessageOverlay, { children: "No simulation selected." });
    }
    const simulationIsOn = currentSimulation.state === SimulationState.On;
    const simulationIsTurningOn = [SimulationState.Creating, SimulationState.TurningOn].includes(currentSimulation.state);
    const simulationIsOff = currentSimulation.state === SimulationState.Off;
    const simulationIsSetup = simulationIsOn && !setupInProgress && !setupError;
    const showTimeRemaining = !!currentSimulation.expires && !setupInProgress && !setupError && currentSimulation.state !== SimulationState.Creating;
    const sidebarButtonCommonProps = {
        currentSelectedType: selectedSidebarButton,
        onClick: (type) => setSelectedSidebarButton(type),
    };
    return (_jsxs("div", { className: "controls-overlay", "data-testid": "controls", children: [_jsxs("div", { className: "toolbar-controls", children: [_jsxs("div", { className: "sidebar", "data-testid": "sidebar", children: [_jsx(SidebarButton, Object.assign({ icon: "QuestionMark", type: "connection-instructions", title: "Connect with other Software" }, sidebarButtonCommonProps)), simulationIsSetup && _jsx(SidebarButton, Object.assign({ icon: "Terminal", type: "terminal", title: "Terminal" }, sidebarButtonCommonProps)), _jsx(SidebarButton, { icon: "Restore", type: "camera", title: "Reset Camera to default position", currentSelectedType: selectedSidebarButton, onClick: type => {
                                    setSelectedSidebarButton(type);
                                    onCameraReset();
                                } }), _jsx("div", { className: "spacer" }), _jsx(FeedbackLink, { title: "Send us feedback", children: _jsx(FeedbackIcon, { appearsClickable: true }) })] }), _jsxs("div", { className: "current-panel", "data-testid": "current-panel", children: [selectedSidebarButton === 'terminal' && simulationIsSetup &&
                                _jsx(Terminal, { simulationId: currentSimulation.id, onClose: () => setSelectedSidebarButton(null) }), selectedSidebarButton === 'connection-instructions' &&
                                _jsx(ViewerConnectionInstructions, { simulation: currentSimulation, onClose: () => setSelectedSidebarButton(null) })] })] }), _jsx("div", { className: "axis-controls", children: _jsxs("div", { children: [_jsxs("div", { className: "on-off-toggle-container", children: [_jsx(VirtualDeviceOnOffToggle, { simulation: currentSimulation }), simulationIsOff && _jsx(Text, { className: "description", children: "Virtual Device is not started yet" }), showTimeRemaining && _jsxs(_Fragment, { children: [loggedIn && _jsxs(_Fragment, { children: [!expired && (moment(currentSimulation.expires).diff(moment(), 'hours') < 1)
                                                    && _jsxs(Text, { className: "time-left", children: [_jsx(TimeLeft, { date: currentSimulation.expires, onTimeExpired: () => setExpired(true) }), " until idle shut-off"] }), expired && _jsx(Text, { className: "time-left", children: "Idle - shutting off soon" })] }), !loggedIn && _jsxs(_Fragment, { children: [!expired && _jsxs(Text, { className: "time-left", children: [_jsx(TimeLeft, { date: currentSimulation.expires, onTimeExpired: () => setExpired(true) }), " usage remaining"] }), expired && _jsx(Text, { className: "time-left", children: "Will be deleted soon" })] })] })] }), !simulationIsOn && !isRetryingTurnOn && _jsxs("div", { className: "info padded icon-message", children: [_jsx(Icons.ErrorNote, {}), simulationIsTurningOn && _jsx(Text, { children: "The simulation is starting. Please wait until the \"Running\" status is displayed before trying to communicate with the device." }), !simulationIsTurningOn && _jsx(Text, { children: "Start the Virtual Device to interact with movement controls and the terminal, or control the Virtual Device using Zaber Launcher or your own applications." })] }), simulationIsOn && _jsxs("div", { className: "axis-list-container", children: [setupInProgress && _jsxs("div", { className: "padded icon-message", children: [_jsx(Icons.Gear, { className: AnimationClasses.Rotation }), _jsx(Text, { children: "Connecting visualization to simulation..." })] }), setupError && _jsxs("div", { className: "error padded icon-message", children: [_jsx(Icons.ErrorFault, {}), _jsx(Text, { children: setupError })] }), simulationIsSetup && !currentSimulation.axes && _jsxs("div", { className: "info padded icon-message", children: [_jsx(Icons.ErrorNote, {}), _jsx(Text, { children: "No devices or axes found" })] }), simulationIsSetup && ((_b = currentSimulation.axes) === null || _b === void 0 ? void 0 : _b.map(axisKey => {
                                    const componentIndex = getComponentIndexForAxis(currentSimulation, axisKey);
                                    return _jsxs(React.Fragment, { children: [_jsx("div", { className: "separator" }), _jsx(ViewerControlsAxis, { axisKey: axisKey, selected: selectedModel === componentIndex, onSelected: () => actions.deviceClicked(componentIndex) })] }, axisKey);
                                }))] })] }) })] }));
};
