import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AnimationClasses, Icons, NoticeBanner, Text } from '@zaber/react-library';
import classNames from 'classnames';
import { useActions } from '../utils';
import { selectAxes, selectErrors, selectIsLoading } from './selectors';
import { actions as actionsDefinition } from './actions';
import { Axis } from './Axis';
export const Demo = () => {
    const actions = useActions(actionsDefinition);
    useEffect(() => {
        actions.mount();
        return () => { actions.unmount(); };
    }, []);
    const axes = useSelector(selectAxes);
    const errors = useSelector(selectErrors);
    const isLoading = useSelector(selectIsLoading);
    return (_jsxs("div", { className: "demo", children: [_jsx("div", { children: _jsx(Text, { t: Text.Type.H2, children: "Zaber IoT Demo" }) }), isLoading && _jsx(Icons.Refresh, { className: classNames('loading', AnimationClasses.Rotation) }), errors.map((error, i) => _jsx(NoticeBanner, { children: error }, i)), axes.map(axis => _jsx(Axis, { axis: axis }, axis.id)), _jsx("div", { className: "explainer", children: "While our devices are not themselves IoT capable, Zaber provides a solution that allows sharing physical and virtual devices between applications and user scripts over the cloud." })] }));
};
