import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Text, LinkClasses } from '@zaber/react-library';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { PageUrls } from '../urls';
import { FeedbackLink } from '../feedback/FeedbackLink';
import { NewTabLink } from './Links';
export const Footer = ({ releaseNotesUrl }) => {
    const currentYear = useMemo(() => new Date().getFullYear(), []);
    return (_jsxs("div", { className: "footer", children: [_jsxs("div", { className: "options", children: [_jsx(Link, { to: PageUrls.PrivacyPolicy, className: LinkClasses.Default, children: _jsx(Text, { children: "Privacy Policy" }) }), _jsx("div", { className: "separator" }), _jsx(Link, { to: PageUrls.TermsAndConditions, className: LinkClasses.Default, children: _jsx(Text, { children: "Terms & Conditions" }) }), _jsx("div", { className: "separator" }), _jsx(FeedbackLink, { className: LinkClasses.Default, children: "Send Us Feedback" }), releaseNotesUrl && _jsxs(_Fragment, { children: [_jsx("div", { className: "separator" }), _jsx(NewTabLink, { to: releaseNotesUrl, className: LinkClasses.Default, children: _jsx(Text, { children: "Release Notes" }) })] })] }), _jsxs(Text, { children: ["\u00A9 ", currentYear, " Zaber Technologies Inc."] })] }));
};
