var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { END, eventChannel } from '@redux-saga/core';
import { ensureError } from '@zaber/toolbox';
import { getContainer } from '../container';
import { IoTService } from '../app_components';
function waitForDocumentToBeVisible() {
    return __awaiter(this, void 0, void 0, function* () {
        if (!document.hidden) {
            return;
        }
        yield new Promise(resolve => {
            const handler = () => {
                if (document.hidden) {
                    return;
                }
                resolve();
                document.removeEventListener('visibilitychange', handler);
            };
            document.addEventListener('visibilitychange', handler);
        });
    });
}
export function createIotSubscriptionEventChannel(topic, autoRenew, renewOnPageVisible) {
    const iot = getContainer().get(IoTService);
    return eventChannel(emit => {
        let topicSubscription;
        let closed = false;
        const reconnectionLoop = () => __awaiter(this, void 0, void 0, function* () {
            do {
                if (renewOnPageVisible) {
                    yield waitForDocumentToBeVisible();
                }
                let subscription;
                try {
                    const connection = yield iot.getConnection();
                    subscription = yield connection.subscribe(topic);
                }
                catch (error) {
                    emit({ error: ensureError(error), ended: true });
                    continue;
                }
                yield new Promise(resolve => {
                    topicSubscription = subscription.subscribe({
                        next: message => emit({ message }),
                        error: error => {
                            emit({ error, ended: true });
                            resolve();
                        },
                        complete: () => {
                            emit({ ended: true });
                            resolve();
                        },
                    });
                    emit({ connected: true });
                });
            } while (autoRenew && !closed);
            emit(END);
        });
        reconnectionLoop().catch(err => {
            emit(ensureError(err));
        });
        return () => {
            closed = true;
            topicSubscription === null || topicSubscription === void 0 ? void 0 : topicSubscription.unsubscribe();
        };
    });
}
