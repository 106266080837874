import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Text } from '@zaber/react-library';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { Footer } from '../components/Footer';
import { NavigationBar } from '../navigation_bar';
import { DeleteAccountInfo } from '../delete_account/DeleteAccountInfo';
import { useActions } from '../utils';
import { actions as actionDefinitions } from '../user/actions';
import { PageTitle } from '../components/PageTitle';
import { selectLoggedInUserEmail } from './selectors';
import { HeaderCardWithTitle } from './HeaderCardWithTitle';
import { Pricing } from './Pricing';
import { UserInformation } from './UserInformation';
import { NoEmailPopup } from './NoEmailPopup';
export const AccountManagement = () => {
    const actions = useActions(actionDefinitions);
    useEffect(() => () => { actions.resetErrors(); }, []);
    const userEmail = useSelector(selectLoggedInUserEmail);
    return (_jsxs("div", { className: "user-account page", children: [_jsx(PageTitle, { children: "Account Management" }), _jsx(NavigationBar, {}), !userEmail && _jsx(NoEmailPopup, {}), userEmail && _jsxs("div", { className: "content", children: [_jsx(Text, { t: Text.Type.H3, e: Text.Emphasis.Light, className: "page-title", children: "Account Management" }), _jsx("div", { className: "card-container", children: _jsx(HeaderCardWithTitle, { text: "User Information", children: _jsx(UserInformation, {}) }) }), _jsx("div", { className: "card-container", children: _jsx(HeaderCardWithTitle, { text: "Pricing", children: _jsx(Pricing, {}) }) }), _jsx("div", { className: "card-container", children: _jsx(HeaderCardWithTitle, { text: "Delete My Account", children: _jsx(DeleteAccountInfo, {}) }) })] }), _jsx(Footer, {})] }));
};
