import { createSelector } from 'reselect';
import { selectFeedback } from '../store';
import { pick } from '../utils';
export const selectFeedbackModalState = createSelector(selectFeedback, feedback => feedback.feedbackModalState);
export const selectFeedbackFor = createSelector(selectFeedback, feedback => feedback.feedbackFor);
export const selectFeedbackText = createSelector(selectFeedback, feedback => feedback.feedbackText);
export const selectFeedbackState = createSelector(selectFeedback, state => pick(state, 'feedbackSubmissionInProgress', 'feedbackError', 'feedbackSubmitted'));
export const selectFeedbackAnonymous = createSelector(selectFeedback, feedback => feedback.feedbackAnonymous);
export const selectFeedbackEmail = createSelector(selectFeedback, feedback => {
    if (feedback.feedbackAnonymous) {
        return;
    }
    return feedback.feedbackEmail;
});
