import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Text } from '@zaber/react-library';
import { useSelector } from 'react-redux';
import { selectCreationRetryInfo, selectSimulationCreationError, selectSimulationRenameError } from '../virtual_device/selectors';
import { IconMessage } from '../components/IconMessage';
const Error = ({ title, children }) => (_jsxs(IconMessage, { icon: "ErrorFault", color: "red", children: [_jsx(Text, { t: Text.Type.H5, className: "title", children: title }), _jsx(Text, { children: children })] }));
export const DeviceManagerErrors = () => {
    const simulationCreationErr = useSelector(selectSimulationCreationError);
    const renameErr = useSelector(selectSimulationRenameError);
    const createRetry = useSelector(selectCreationRetryInfo);
    const isRetrying = (createRetry && createRetry === 'chain');
    return (_jsxs("div", { className: "error-container", children: [simulationCreationErr && !isRetrying && _jsx(Error, { title: "Unable to create Virtual Device", children: simulationCreationErr }), renameErr && _jsx(Error, { title: "Unable to rename Virtual Device", children: renameErr })] }));
};
