import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import { getTutorialMetadata, getTutorialUrl } from './content';
import { SameTabLink } from '../components/Links';
import { PageUrls } from '../urls';
import { Icons, Text } from '@zaber/react-library';
export const Breadcrumbs = ({ tutorial }) => {
    const crumbs = useMemo(() => {
        const crumbs = [];
        let meta = getTutorialMetadata(tutorial);
        crumbs.unshift(meta);
        while (meta.parent) {
            meta = getTutorialMetadata(meta.parent);
            crumbs.unshift(meta);
        }
        return crumbs;
    }, [tutorial]);
    return _jsxs("div", { className: "breadcrumbs", children: [_jsx(SameTabLink, { to: PageUrls.ZaberLauncherTutorials, children: "All Tutorials" }), crumbs.map((crumb, i) => _jsxs(React.Fragment, { children: [_jsx(Icons.ArrowCollapsed, {}), i < crumbs.length - 1 && _jsx(SameTabLink, { to: getTutorialUrl(crumb.key), children: crumb.title }), i === crumbs.length - 1 && _jsx(Text, { e: Text.Emphasis.Bold, children: crumb.title })] }, i))] });
};
