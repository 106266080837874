import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import _ from 'lodash';
import moment, { duration } from 'moment';
import { useEffect, useState, memo } from 'react';
// eslint-disable-next-line react/display-name
export const TimeLeft = memo(({ date, onTimeExpired }) => {
    const [now, setNow] = useState(moment());
    useEffect(() => {
        const interval = setInterval(() => setNow(moment()), 1000);
        return () => clearInterval(interval);
    }, []);
    const timeLeft = Math.max(moment(date).diff(now), 0);
    if (timeLeft <= 0) {
        onTimeExpired === null || onTimeExpired === void 0 ? void 0 : onTimeExpired();
    }
    const display = duration(timeLeft).humanize();
    return _jsx(_Fragment, { children: _.capitalize(display) });
});
