import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Text, Icons, AnimationClasses, Chevron } from '@zaber/react-library';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useActions } from '../utils';
import { actions as actionDefinitions } from './actions';
import { selectAccessTokenDeletionErrors, selectAccessTokens, selectAccessTokensLoading, selectTokenIdSelectedForDeletion } from './selectors';
import { AVAILABLE_ACCESS_TOKEN_CREATED_BY_READABLE_NAME, ACCESS_TOKEN_SCOPES_DISPLAY_DATA } from './types';
import { TokenDeletionPopup } from './TokenDeletionPopup';
function formatDateTime(date) {
    return moment(date).format('lll');
}
export const AccessTokenList = ({ createdBy }) => {
    const actions = useActions(actionDefinitions);
    const tokensLoading = useSelector(selectAccessTokensLoading);
    const tokens = useSelector(selectAccessTokens);
    const tokenIdSelectedForDeletion = useSelector(selectTokenIdSelectedForDeletion);
    const tokenDeletionErrors = useSelector(selectAccessTokenDeletionErrors);
    const [showTable, setShowTable] = useState(createdBy ? false : true);
    const tokensCreatedByTarget = tokens === null || tokens === void 0 ? void 0 : tokens.filter(token => token.createdBy === createdBy);
    useEffect(() => {
        actions.loadAccessTokens();
    }, []);
    if (!tokensCreatedByTarget || tokensCreatedByTarget.length === 0) {
        return null;
    }
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: "existing-tokens-title", children: [_jsx(Chevron, { expanded: showTable, onClick: () => setShowTable(!showTable) }), _jsx(Text, { t: Text.Type.H4, children: createdBy ?
                            `Access Tokens Used By ${AVAILABLE_ACCESS_TOKEN_CREATED_BY_READABLE_NAME[createdBy]}` : 'Your Access Tokens' }), _jsx(Icons.Refresh, { className: "refresh-icon", appearsClickable: true, onClick: () => actions.loadAccessTokens() })] }), showTable && _jsxs(_Fragment, { children: [tokensLoading && _jsx(Icons.Gear, { className: AnimationClasses.Rotation }), tokensCreatedByTarget && tokensCreatedByTarget.length > 0 && _jsx("table", { className: "existing-tokens", children: _jsxs("tbody", { children: [_jsxs("tr", { children: [_jsx("th", { children: _jsx(Text, { e: Text.Emphasis.Bold, children: "Name" }) }), _jsx("th", { children: _jsx(Text, { e: Text.Emphasis.Bold, children: "Created At" }) }), _jsx("th", { children: _jsx(Text, { e: Text.Emphasis.Bold, children: "Last Used At" }) }), _jsx("th", { children: _jsx(Text, { e: Text.Emphasis.Bold, children: "Last Used By" }) }), _jsx("th", { children: _jsx(Text, { e: Text.Emphasis.Bold, children: "Expires" }) }), _jsx("th", { children: _jsx(Text, { e: Text.Emphasis.Bold, children: "Scopes" }) }), _jsx("th", { children: _jsx(Text, { e: Text.Emphasis.Bold, children: "Delete" }) })] }), tokensCreatedByTarget.map(token => {
                                    var _a;
                                    return _jsxs("tr", { children: [_jsx("td", { className: "text-column", children: _jsx(Text, { children: token.description }) }), _jsx("td", { children: _jsx(Text, { children: formatDateTime(token.createdAt) }) }), _jsx("td", { children: _jsx(Text, { children: token.lastUsedAt && formatDateTime(token.lastUsedAt) }) }), _jsx("td", { className: "text-column", children: _jsx(Text, { children: token.lastUsedBy }) }), _jsx("td", { children: _jsx(Text, { children: token.expiry && formatDateTime(token.expiry) }) }), _jsx("td", { className: "text-column", children: _jsx(Text, { children: (_a = token.scopes) === null || _a === void 0 ? void 0 : _a.map(scope => ACCESS_TOKEN_SCOPES_DISPLAY_DATA[scope].title).join(', ') }) }), _jsxs("td", { children: [_jsx(Icons.Trash, { appearsClickable: true, disabled: tokenIdSelectedForDeletion !== null, onClick: () => actions.chooseTokenForDeletion(token.tokenId), "data-testid": "delete-icon" }), tokenIdSelectedForDeletion === token.tokenId
                                                        && _jsx(TokenDeletionPopup, { error: tokenDeletionErrors[token.tokenId], tokenId: tokenIdSelectedForDeletion, tokenName: token.description })] })] }, token.tokenId);
                                })] }) })] })] }));
};
