import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import Markdown from 'marked-react';
import { getTutorialMarkdown, getTutorialMetadata } from './content';
import { CustomRenderer } from './CustomRenderer';
import { Breadcrumbs } from './Breadcrumbs';
import { LastUpdated } from './LastUpdated';
export const Tutorial = ({ tutorial }) => {
    const metadata = useMemo(() => getTutorialMetadata(tutorial), [tutorial]);
    const markdown = useMemo(() => getTutorialMarkdown(tutorial), [tutorial]);
    return _jsxs("div", { className: "tutorial", children: [_jsxs("div", { className: "breadcrumbs-bar", children: [_jsx(Breadcrumbs, { tutorial: tutorial }), _jsx("div", { className: "spacer" }), _jsx(LastUpdated, { date: metadata.date })] }), _jsx(Markdown, { value: markdown, renderer: CustomRenderer })] });
};
