import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Text } from '@zaber/react-library';
import zlLogo from '../assets/zl_logo.svg?url';
import { NewTabLink } from '../components/Links';
import { PageUrls } from '../urls';
import { LinkWithImage } from './LinkWithImage';
import { getTutorialUrl, getVideoPosterUrl, getTutorialMetadata } from './content';
import { LinkWithIcon } from './LinkWithIcon';
import { ZL_APP_ICONS } from './icons';
import { TUTORIALS } from './markdown/content_generated';
import _ from 'lodash';
const headingOrder = ['Getting Started', 'Generic Apps', 'Specialized Apps'];
export const TableOfContents = () => {
    const subjectsByCategory = {};
    for (const heading of headingOrder) {
        subjectsByCategory[heading] = [];
    }
    for (const key of Object.keys(TUTORIALS)) {
        const meta = getTutorialMetadata(key);
        const category = meta.category;
        if (!headingOrder.includes(category)) {
            throw new Error(`Unsupported category: ${category}`);
        }
        // Only include top-level documents in the ToC.
        if (meta.parent == null) {
            subjectsByCategory[category] = [...subjectsByCategory[category], meta];
        }
    }
    return _jsxs("div", { className: "toc", children: [_jsxs("div", { className: "title", children: [_jsx(Text, { t: Text.Type.H1, children: "Zaber Launcher Tutorials" }), _jsx("div", { className: "spacer" }), _jsxs(NewTabLink, { to: `${PageUrls.ZaberLauncherDownloadPage}#download-section`, className: "download-link", children: [_jsx("div", { className: "img-border", children: _jsx("img", { src: zlLogo, alt: "Zaber Launcher" }) }), _jsxs(Text, { t: Text.Type.BodySm, children: ["Download", _jsx("br", {}), "Zaber Launcher"] })] })] }), _jsxs("div", { className: "intro", children: [_jsx(Text, { t: Text.Type.H2, children: "Learn With Zaber" }), _jsx(Text, { children: "Whether you're a beginner or an advanced user, explore our tutorials to find the right guidance for your needs. Learn how to set up, move, tune or integrate Zaber devices with step-by-step instructions for each application." })] }), headingOrder.map((heading, i) => (_jsxs("div", { className: "category", children: [_jsx(Text, { t: Text.Type.H3, children: heading }), _jsx("div", { className: "links", children: subjectsByCategory[heading].map(meta => (_jsxs(React.Fragment, { children: [i === 0 && _jsx(LinkWithImage, { to: getTutorialUrl(meta.key), imageUrl: getVideoPosterUrl(meta.poster), label: meta.title }), i > 0 && _jsx(LinkWithIcon, { to: getTutorialUrl(meta.key), iconUrl: ZL_APP_ICONS[meta.icon], label: meta.title })] }, `link-${meta.key}`))) })] }, `heading-${_.camelCase(heading)}`)))] });
};
