import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Input, Button, Text, LinkClasses } from '@zaber/react-library';
import { Link } from 'react-router-dom';
import { useActions } from '@zaber/toolbox/lib/redux';
import { NavigationBar } from '../navigation_bar';
import { PageUrls } from '../urls';
import { Footer } from '../components/Footer';
import { useJustMounted } from '../app_components';
import { IconMessage } from '../components/IconMessage';
import { InputValidation, useValidation } from '../components/InputValidation';
import { environment } from '../environment';
import { TimedVisibility } from '../components/TimedVisibility';
import { PageTitle } from '../components/PageTitle';
import { selectResendSignUpEmail, selectSignUpData, selectSignUpError, selectSignUpInProgress } from './selectors';
import { actions as actionDefinitions } from './actions';
import { PASSWORD_AGAIN_VALIDATION_REQUIREMENTS, PRODUCTION_ENV_PASSWORD_VALIDATION_REQUIREMENTS, NON_PRODUCTION_ENV_PASSWORD_VALIDATION_REQUIREMENTS } from './types';
// TODO: errors, password validation, email validation
const passwordValidationRequirements = environment.build === 'production'
    ? PRODUCTION_ENV_PASSWORD_VALIDATION_REQUIREMENTS
    : NON_PRODUCTION_ENV_PASSWORD_VALIDATION_REQUIREMENTS;
const Success = () => {
    const signUpData = useSelector(selectSignUpData);
    const justMounted = useJustMounted();
    const { resendSignUpEmailInProgress, resendSignUpEmailError } = useSelector(selectResendSignUpEmail);
    const actions = useActions(actionDefinitions);
    const resendButton = (_jsx(Button, { color: "grey", disabled: resendSignUpEmailInProgress, onClick: () => actions.resendSignUpEmail(signUpData.email), children: "Resend Verification Email" }));
    return (_jsxs("div", { className: "success content", children: [_jsx(Text, { t: Text.Type.H1, e: Text.Emphasis.Light, children: "Welcome to Zaber Cloud!" }), _jsx(IconMessage, { className: "email-sent", icon: "Tick", children: _jsxs(Text, { e: Text.Emphasis.Bold, children: ["Verification email sent to ", signUpData.email] }) }), _jsx(Text, { children: "Please follow the instructions in the email to verify your email address." }), _jsx(Text, { className: "did-not-get-email", children: "Did not receive an email?" }), _jsx(TimedVisibility, { timeout: 5000, altComponent: resendButton, trigger: !justMounted && !resendSignUpEmailInProgress && !resendSignUpEmailError, children: _jsx(IconMessage, { icon: "Tick", color: "green", children: _jsxs(Text, { e: Text.Emphasis.Bold, children: ["Another verification email has been sent to ", signUpData.email] }) }) }), resendSignUpEmailError && _jsxs(IconMessage, { icon: "ErrorFault", color: "red", children: [_jsxs(Text, { e: Text.Emphasis.Bold, children: ["We encountered an error while sending a verification email to ", signUpData.email] }), _jsx("br", {}), _jsx(Text, { children: resendSignUpEmailError })] })] }));
};
export const SignUp = () => {
    const signUpData = useSelector(selectSignUpData);
    const inProgress = useSelector(selectSignUpInProgress);
    const error = useSelector(selectSignUpError);
    const actions = useActions(actionDefinitions);
    const justMounted = useJustMounted();
    const showSuccessMessage = useMemo(() => !justMounted && !inProgress && !error, [inProgress]);
    useEffect(() => () => {
        actions.updateSignUpData({ password: '', passwordAgain: '' });
        actions.resetErrors();
    }, []);
    const emailIsValid = signUpData.email.match(/.+@.+/); // Not quite standards compliant but close enough for now.
    const [passwordValidations, passwordIsValid] = useValidation(passwordValidationRequirements, signUpData.password);
    const [passwordAgainValidations, passwordAgainIsValid] = useValidation(PASSWORD_AGAIN_VALIDATION_REQUIREMENTS, signUpData.password, signUpData.passwordAgain);
    const [highlightInvalidValidations, setHighlightInvalidValidations] = useState(false);
    return (_jsxs("div", { className: "sign-up page", children: [_jsx(PageTitle, { children: "Sign Up" }), _jsx(NavigationBar, {}), showSuccessMessage && _jsx(Success, {}), !showSuccessMessage && _jsxs("div", { className: "normal content", children: [_jsx(Text, { t: Text.Type.H1, e: Text.Emphasis.Light, children: "Create an Account" }), _jsx(Text, { t: Text.Type.H4, className: "info centered", children: "Creating an account allows you to use the Virtual Device system and create access tokens to enable IoT control of your physical Zaber products." }), _jsx(Text, { t: Text.Type.H5, className: "info centered", children: "NOTE: The Virtual Device system is currently undergoing early access testing. Please send us feedback!" }), _jsxs("div", { className: "input-container", children: [error && _jsx(IconMessage, { icon: "ErrorFault", color: "red", className: "error", children: error }), _jsx(Input, { type: "text", name: "email", labelContent: "Email Address", disabled: inProgress, value: signUpData.email, onChange: e => actions.updateSignUpData({ email: e.target.value }) }), _jsx(Input, { type: "password", name: "password", labelContent: "Password", disabled: inProgress, value: signUpData.password, status: highlightInvalidValidations && !passwordIsValid ? 'error' : null, onChange: e => actions.updateSignUpData({ password: e.target.value }) }), _jsx(InputValidation, { validations: passwordValidations, highlightInvalidValidations: highlightInvalidValidations }), _jsx(Input, { type: "password", name: "password-again", labelContent: "Confirm Password", disabled: inProgress, value: signUpData.passwordAgain, status: highlightInvalidValidations && !passwordAgainIsValid ? 'error' : null, onChange: e => actions.updateSignUpData({ passwordAgain: e.target.value }) }), _jsx(InputValidation, { validations: passwordAgainValidations, highlightInvalidValidations: highlightInvalidValidations })] }), _jsx(Button, { disabled: inProgress || !emailIsValid, onClick: () => {
                            setHighlightInvalidValidations(!passwordIsValid || !passwordAgainIsValid);
                            if (passwordIsValid && passwordAgainIsValid) {
                                actions.signUp();
                            }
                        }, children: "Create Account" }), _jsx(Link, { to: PageUrls.Login, className: LinkClasses.Default, children: _jsx(Text, { children: "Login to an existing account" }) })] }), _jsx(Footer, {})] }));
};
