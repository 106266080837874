import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Text, Button, Icons } from '@zaber/react-library';
import { useMemo } from 'react';
import { useActions } from '@zaber/toolbox/lib/redux';
import { NewTabLink } from '../components/Links';
import { makeRootKey } from '../virtual_device';
import { useAxis, useDevice, useSimulation } from '../virtual_device/hooks';
import { getProtocolManualLink } from '../urls';
import { actions as actionDefinitions } from './actions';
import { TerminalInput } from './TerminalInput';
import { TerminalHistory } from './TerminalHistory';
import { CopyAllHistoryButton } from './CopyAllHistoryButton';
export const Terminal = ({ simulationId, onClose }) => {
    var _a, _b;
    const simKey = makeRootKey(simulationId);
    const actions = useActions(actionDefinitions);
    const currentSimulation = useSimulation(simKey);
    const selectedAxisKey = (_b = (_a = currentSimulation === null || currentSimulation === void 0 ? void 0 : currentSimulation.axes) === null || _a === void 0 ? void 0 : _a[0]) !== null && _b !== void 0 ? _b : null; // NOTE assuming first axis until we have a selection mechanism.
    const selectedAxis = useAxis(selectedAxisKey);
    const selectedDevice = useDevice(selectedAxisKey);
    const commandReferenceUrl = useMemo(() => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
        if (((_b = (_a = currentSimulation === null || currentSimulation === void 0 ? void 0 : currentSimulation.components) === null || _a === void 0 ? void 0 : _a.length) !== null && _b !== void 0 ? _b : 0) < 2) {
            return (_f = (_e = (_d = (_c = currentSimulation === null || currentSimulation === void 0 ? void 0 : currentSimulation.components) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 : _d.modelManifest) === null || _e === void 0 ? void 0 : _e.productInfoUrl) !== null && _f !== void 0 ? _f : getProtocolManualLink({
                device: (_h = (_g = selectedDevice === null || selectedDevice === void 0 ? void 0 : selectedDevice.identity) === null || _g === void 0 ? void 0 : _g.name) !== null && _h !== void 0 ? _h : (_k = (_j = currentSimulation === null || currentSimulation === void 0 ? void 0 : currentSimulation.components) === null || _j === void 0 ? void 0 : _j[0]) === null || _k === void 0 ? void 0 : _k.productName,
                peripheral: (_l = selectedAxis === null || selectedAxis === void 0 ? void 0 : selectedAxis.identity) === null || _l === void 0 ? void 0 : _l.peripheralName,
                firmware: (_m = selectedDevice === null || selectedDevice === void 0 ? void 0 : selectedDevice.identity) === null || _m === void 0 ? void 0 : _m.firmwareVersion,
            });
        }
        return getProtocolManualLink({ firmware: (_o = selectedDevice === null || selectedDevice === void 0 ? void 0 : selectedDevice.identity) === null || _o === void 0 ? void 0 : _o.firmwareVersion });
    }, [simulationId]);
    return (_jsxs("div", { className: "terminal-container", children: [_jsx(Text, { className: "title", t: Text.Type.H3, children: "Terminal" }), _jsx(Icons.Cross, { onClick: onClose, interactionColor: "grey", title: "Close the terminal" }), _jsx("br", {}), _jsx(TerminalHistory, { className: "history", simulationId: simulationId }), _jsxs("div", { className: "buttons-container", children: [_jsx(CopyAllHistoryButton, { simulationId: simulationId, color: "clear", children: "Copy All" }), _jsx(Button, { color: "clear", onClick: () => actions.clearConnectionHistory(simulationId), title: "Clear history", children: "Clear All" })] }), _jsx(TerminalInput, { simulationId: simulationId, onSendCommand: command => {
                    if (!command) {
                        return;
                    }
                    if (!command.startsWith('/')) {
                        command = `/${command}`;
                    }
                    actions.sendCommand(simulationId, command);
                } }), _jsx(NewTabLink, { to: commandReferenceUrl, children: _jsx(Text, { children: "Command Reference" }) })] }));
};
