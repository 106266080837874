import { jsx as _jsx } from "react/jsx-runtime";
import { Component } from 'react';
import * as Sentry from '@sentry/react';
import { ErrorReport } from './ErrorReport';
import { errorEmitter } from './errors';
export class ErrorHandler extends Component {
    constructor(props) {
        super(props);
        this.fallback = () => (_jsx(ErrorReport, {}));
        this.onError = () => {
            this.setState({
                hasError: true,
            });
        };
        this.state = {};
        this.onError = this.onError.bind(this);
        errorEmitter.on('error', this.onError);
    }
    componentWillUnmount() {
        errorEmitter.off('error', this.onError);
    }
    render() {
        const { hasError } = this.state;
        if (hasError) {
            return _jsx(ErrorReport, {});
        }
        return (_jsx(Sentry.ErrorBoundary, { fallback: this.fallback, children: this.props.children }));
    }
}
