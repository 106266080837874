import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Text, UnorderedList } from '@zaber/react-library';
import { NavigationBar } from '../navigation_bar';
import { PageTitle } from '../components/PageTitle';
const DownloadLinks = {
    Windows: 'https://zaber-launcher-release.s3-us-west-2.amazonaws.com/public-offline/ZaberLauncherOfflineSetup.exe',
    Mac: 'https://zaber-launcher-release.s3-us-west-2.amazonaws.com/public-offline/ZaberLauncherOffline.dmg',
    Linux: 'https://zaber-launcher-release.s3-us-west-2.amazonaws.com/public-offline/ZaberLauncherOffline.AppImage',
};
export const ZaberLauncherOfflinePage = () => (_jsxs("div", { className: "zaber-launcher-offline page", children: [_jsx(PageTitle, { children: "Zaber Launcher Offline" }), _jsx(NavigationBar, {}), _jsxs("div", { className: "content", children: [_jsx(Text, { t: Text.Type.H2, children: "OFFLINE ZABER LAUNCHER" }), _jsx(Text, { children: "A version of Zaber Launcher that works completely without internet connectivity. This version of Zaber Launcher comes bundled with our entire device database, allowing it to identify and get the properties of devices without querying our API." }), _jsx(Text, { children: "Note that regular Zaber Launcher is able to function without internet in most circumstances. Once a device is connected and identified Launcher will save all the information related to that device locally and not require any further internet access. Therefore, we recommend not using Zaber Launcher Offline unless you must use it exclusively in an offline environment." }), _jsx(UnorderedList, { header: _jsx(Text, { children: "The following cannot work in Zaber Launcher Offline. If you require any of these features, use regular Zaber Launcher instead:" }), children: [
                        'Cloud and IoT connections',
                        'Firmware updates (except with an FWU file)',
                        'Advanced hardware setup and current tuning',
                        'Automatic updates. To update Offline Launcher you must download and reinstall a newer version.',
                        [
                            'Support for any devices running firmware that is newer than your version of the application.',
                            'Support for new firmware requires a reinstall.'
                        ].join(' '),
                    ] }), _jsx(Text, { t: Text.Type.H3, children: "Download" }), _jsx(Text, { children: "If you believe Zaber Launcher Offline is right for your use case, download it below:" }), Object.entries(DownloadLinks).map(([name, link]) => _jsx("a", { href: link, children: name }, name))] })] }));
