import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { AnimationClasses, Button, Checkbox, Icons, Input, Modal, Text, TextArea } from '@zaber/react-library';
import { useActions } from '@zaber/toolbox/lib/redux';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import isEmail from 'validator/es/lib/isEmail';
import { useWindowEvent } from '../app_components';
import { IconMessage } from '../components/IconMessage';
import { Survey } from '../components/Survey';
import { actions as actionDefinitions } from './actions';
import { selectFeedbackEmail, selectFeedbackState, selectFeedbackModalState, selectFeedbackText, selectFeedbackAnonymous, selectFeedbackFor, } from './selectors';
function useCloseFeedbackOnEscape(actions) {
    useWindowEvent('keydown', ({ key }) => {
        if (key === 'Escape') {
            actions.close();
        }
    });
}
const Gather = () => {
    const actions = useActions(actionDefinitions);
    const anonymous = useSelector(selectFeedbackAnonymous);
    const email = useSelector(selectFeedbackEmail);
    const text = useSelector(selectFeedbackText);
    const whatFor = useSelector(selectFeedbackFor);
    const [emailInvalid, setEmailInvalid] = useState(false);
    const [textInvalid, setTextInvalid] = useState(false);
    useCloseFeedbackOnEscape(actions);
    return _jsxs(_Fragment, { children: [whatFor === 'virtual-device' && _jsx(Survey, {}), whatFor === 'virtual-device' && _jsx(Text, { children: "Do you have a suggestion or have you found a bug? Let us know in the field below." }), whatFor === 'tutorials' && _jsx(Text, { children: "Was this information useful? Let us know what's good or what could be improved." }), _jsx(TextArea, { value: text, onValueChange: actions.setText, title: "Input feedback" }), textInvalid && _jsx(IconMessage, { color: "red", icon: "ErrorFault", children: "Feedback text can not be empty" }), _jsx(Checkbox, { labelContent: "Send feedback anonymously", checked: anonymous, onChecked: actions.setAnonymous }), !anonymous && _jsxs(_Fragment, { children: [_jsx(Input, { labelContent: "Email", value: email, onValueChange: actions.setEmail, title: "Input email" }), emailInvalid && _jsx(IconMessage, { color: "red", icon: "ErrorFault", children: "Please provide a valid email address" })] }), _jsx("div", { className: "spacer" }), _jsx(Button, { title: "Send feedback", onClick: () => {
                    const emailIsValid = anonymous ? true : isEmail(email !== null && email !== void 0 ? email : '');
                    const textIsValid = !!text;
                    setEmailInvalid(!emailIsValid);
                    setTextInvalid(!textIsValid);
                    if (emailIsValid && textIsValid) {
                        actions.sendFeedback();
                    }
                }, children: "Send" })] });
};
const Info = ({ state }) => {
    const actions = useActions(actionDefinitions);
    const { feedbackError } = useSelector(selectFeedbackState);
    useCloseFeedbackOnEscape(actions);
    switch (state) {
        case 'sending':
            return _jsxs(_Fragment, { children: [_jsx(Icons.Gear, { className: AnimationClasses.Rotation }), _jsx(Text, { t: Text.Type.Body, children: "Sending your feedback" })] });
        case 'sent':
            return _jsxs(_Fragment, { children: [_jsx(Icons.Confirmation, { className: "confirmation" }), _jsx(Text, { t: Text.Type.Body, children: "We appreciate you taking the time to share your thoughts!" })] });
        case 'error':
            return _jsxs(_Fragment, { children: [_jsx(Icons.ErrorFault, { className: "send-error" }), _jsx(Text, { t: Text.Type.Body, children: "There was a problem sending your feedback." }), _jsx(Text, { className: "error-text", t: Text.Type.BodySm, e: Text.Emphasis.Light, children: feedbackError }), _jsx(Button, { onClick: actions.sendFeedback, title: "Send feedback", children: "Try Again" })] });
    }
};
export const FeedbackModal = () => {
    const actions = useActions(actionDefinitions);
    const feedbackModalState = useSelector(selectFeedbackModalState);
    useEffect(() => {
        const open = () => { actions.open(); };
        window.addEventListener('zaber-open-feedback', open);
        return () => { window.removeEventListener('zaber-open-feedback', open); };
    }, []);
    if (feedbackModalState === 'closed') {
        return null;
    }
    return _jsx(Modal, { className: classNames('feedback-modal', { center: feedbackModalState !== 'gather' }), headerIcon: _jsx(Icons.Feedback, {}), headerText: "Send Feedback", onRequestClose: actions.close, children: feedbackModalState === 'gather' ? _jsx(Gather, {}) : _jsx(Info, { state: feedbackModalState }) });
};
