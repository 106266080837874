import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useSelector } from 'react-redux';
import { Button, Text, Modal, Icons, AnimationClasses } from '@zaber/react-library';
import { useActions } from '../utils';
import { actions as actionDefinitions } from './actions';
import { selectAccessTokenIdsBeingDeleted } from './selectors';
const TokenDeletionPopupContent = ({ tokenId, tokenName, error }) => {
    const actions = useActions(actionDefinitions);
    const accessTokensBeingDeleted = useSelector(selectAccessTokenIdsBeingDeleted);
    if (error) {
        return (_jsxs("div", { className: "delete-access-token-popup-content", children: [_jsxs("div", { children: [_jsx(Text, { children: "There was an error deleting the access token \u00A0" }), _jsx(Text, { e: Text.Emphasis.Bold, f: Text.Family.Mono, children: tokenName }), _jsxs(Text, { children: [". ", error, " Please try again. If the error persists refresh the page or sign out and sign back in again."] })] }), _jsxs("div", { className: "button-container", children: [_jsx(Button, { onClick: () => actions.deleteAccessToken(tokenId), children: "Try Again" }), _jsx(Button, { color: "grey", onClick: () => {
                                actions.accessTokenDeletionError(tokenId);
                                actions.chooseTokenForDeletion(null);
                            }, children: "Cancel" })] })] }));
    }
    if (accessTokensBeingDeleted.includes(tokenId)) {
        return (_jsxs("div", { className: "deleting-token", children: [_jsx(Icons.Gear, { className: AnimationClasses.Rotation }), _jsx(Text, { children: "Deleting Access Token" })] }));
    }
    return (_jsxs("div", { className: "delete-access-token-popup-content", children: [_jsx(Text, { children: "Deleted Access Tokens cannot be recovered. Any existing applications or scripts that use this token will be unable to access any Zaber Software Services." }), _jsxs("div", { children: [_jsx(Text, { children: "Are you sure you want to " }), _jsx(Text, { e: Text.Emphasis.Bold, children: "permanently delete" }), _jsxs(Text, { children: [" the token named \"", tokenName, "\"?"] })] }), _jsxs("div", { className: "button-container", children: [_jsx(Button, { onClick: () => actions.deleteAccessToken(tokenId), "data-testid": "delete-button", children: "Delete" }), _jsx(Button, { color: "grey", onClick: () => actions.chooseTokenForDeletion(null), children: "Cancel" })] })] }));
};
export const TokenDeletionPopup = ({ tokenId, tokenName, error }) => {
    const actions = useActions(actionDefinitions);
    return _jsx(Modal, { headerIcon: error ? _jsx(Icons.ErrorFault, {}) : _jsx(Icons.ErrorWarning, {}), headerText: "Access Token Deletion", onRequestClose: () => actions.chooseTokenForDeletion(null), small: true, children: _jsx(TokenDeletionPopupContent, { tokenId: tokenId, tokenName: tokenName, error: error }) });
};
