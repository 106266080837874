import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Text, Icons, AnimationClasses, Input, Checkbox, Button, NoticeBanner, DateInput } from '@zaber/react-library';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import copy from 'copy-to-clipboard';
import { useActions } from '../utils';
import { IconMessage } from '../components/IconMessage';
import { AVAILABLE_ACCESS_TOKEN_SCOPES } from '../app_components';
import { actions as actionDefinitions } from './actions';
import { selectAccessTokenCreatedData, selectAccessTokenCreating, selectAccessTokenCreationError } from './selectors';
import { ACCESS_TOKEN_SCOPES_DISPLAY_DATA } from './types';
export const CreateToken = () => {
    const [description, setDescription] = useState('');
    const [expiryDate, setExpiryDate] = useState('');
    const [scopes, setScopes] = useState([]);
    const [copied, setCopied] = useState(false);
    const isCreating = useSelector(selectAccessTokenCreating);
    const createdTokenData = useSelector(selectAccessTokenCreatedData);
    const creationError = useSelector(selectAccessTokenCreationError);
    const actions = useActions(actionDefinitions);
    useEffect(() => () => {
        actions.clearCreatedAccessTokenData();
    }, []);
    if (isCreating) {
        return (_jsxs("div", { className: "access-token-created-form", children: [_jsx(Text, { t: Text.Type.H4, children: "New Access Token" }), _jsxs("div", { className: "creating-token", children: [_jsx(Icons.Gear, { className: AnimationClasses.Rotation }), _jsx(Text, { children: "Creating Access Token" })] })] }));
    }
    if (createdTokenData) {
        return (_jsxs("div", { className: "access-token-created-form", children: [_jsx(Text, { t: Text.Type.H4, children: "New Access Token" }), _jsxs("div", { className: "new-access-token", children: [_jsx(Text, { e: Text.Emphasis.Bold, children: "Created Token:" }), _jsx(Text, { children: createdTokenData.token }), _jsx(Icons.Copy, { className: "copy-icon", appearsClickable: true, onClick: () => {
                                copy(createdTokenData.token);
                                setCopied(true);
                            } }), copied && _jsx(IconMessage, { icon: "Confirmation", color: "green", children: "Copied" })] }), _jsx(NoticeBanner, { type: "info", children: "Copy this token to a secure location. You will not be able to see this token value ever again." }), _jsx("div", { className: "button-container", children: _jsx(Button, { color: "grey", onClick: () => {
                            actions.clearCreatedAccessTokenData();
                            setDescription('');
                            setExpiryDate('');
                            setScopes([]);
                        }, children: "Create Another Access Token" }) })] }));
    }
    return (_jsxs("div", { className: "access-token-creation-form", children: [_jsx(Text, { t: Text.Type.H4, children: "New Access Token" }), creationError && _jsxs(NoticeBanner, { children: ["There was an error creating access token: ", creationError, " Please try again."] }), _jsxs("div", { className: "field", children: [_jsx(Text, { e: Text.Emphasis.Bold, className: "field-title", children: "Token Name" }), _jsx(Input, { value: description, onChange: e => setDescription(e.target.value), "data-testid": "token-name" }), _jsxs("div", { className: "tip", children: [_jsx(Text, { e: Text.Emphasis.Bold, children: "Tip: " }), _jsx(Text, { children: "Provide a unique name to differentiate from other Access Tokens" })] })] }), _jsxs("div", { className: "field", children: [_jsx(Text, { e: Text.Emphasis.Bold, className: "field-title", children: "Expiry Date (Optional)" }), _jsx(DateInput, { value: expiryDate, onChange: e => setExpiryDate(e.target.value), "data-testid": "token-expiry" })] }), _jsxs("div", { className: "field scope", children: [_jsx(Text, { e: Text.Emphasis.Bold, className: "field-title", children: "Scope (Optional)" }), _jsxs("div", { className: "scopes", children: [_jsx(Text, { t: Text.Type.Instruction, children: "Restrict this access token so that it can only access the functionalities you select. If nothing is selected then this access token can be used for any of the functionalities listed below (including any new functionalities that will be added in the future)." }), AVAILABLE_ACCESS_TOKEN_SCOPES.map(scope => (_jsx("div", { children: _jsx(Checkbox, { labelContent: ACCESS_TOKEN_SCOPES_DISPLAY_DATA[scope].title, value: scope, onChange: () => {
                                        setScopes(scopes.includes(scope) ? scopes.filter(storedScope => storedScope !== scope) : scopes.concat(scope));
                                    }, checked: scopes.includes(scope), onChecked: () => null, bold: true, children: _jsx(Text, { children: ACCESS_TOKEN_SCOPES_DISPLAY_DATA[scope].description }) }) }, scope)))] })] }), _jsxs("div", { className: "button-container", children: [_jsx(Button, { color: "grey", disabled: isCreating, onClick: () => actions.updateCreateAccessTokenWanted(false), children: "Cancel" }), _jsx(Button, { disabled: isCreating || !description, onClick: () => actions.createAccessToken(description, expiryDate, scopes), children: "Create Access Token" })] })] }));
};
