var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { MotionType } from '@zaber/virtual-device-display/dist/lib/types';
import { Mutex } from 'async-mutex';
import { injectable } from 'inversify';
import { ZaberApi } from '../app_components';
import { getContainer } from '../container';
import { reduxStoreSymbol } from '../store';
import { AuthState } from '../user';
import { selectAuthState } from '../user/selectors';
import { SimulationState, SimulationType } from './types';
export * from './types';
const mutex = new Mutex();
export const getProductImageUrl = function (deviceId, api) {
    return __awaiter(this, void 0, void 0, function* () {
        var _a;
        try {
            const productInfo = yield api.getWebsiteImageForProducts([deviceId]);
            if ((_a = productInfo[0]) === null || _a === void 0 ? void 0 : _a.thumbnail) {
                return `https://www.zaber.com${productInfo[0].thumbnail}`;
            }
            // eslint-disable-next-line no-empty
        }
        catch (_b) { }
        return null;
    });
};
let VirtualDeviceApi = class VirtualDeviceApi {
    constructor() {
        this.productsByName = new Map();
        this.productsById = new Map();
        this.manifestCache = new Map();
        this.deviceInfoCache = new Map();
        this.firmwareVersion = undefined;
    }
    // This should be called before any other methods of this class.
    loadProductDefinitions() {
        return __awaiter(this, void 0, void 0, function* () {
            yield mutex.runExclusive(() => __awaiter(this, void 0, void 0, function* () {
                if (this.productsByName.size > 0) {
                    return;
                }
                const api = getContainer().get(ZaberApi);
                const [products, fwVersions] = yield Promise.all([
                    api.listVirtualDeviceProducts(2),
                    api.listVirtualDeviceFirmwareVersions()
                ]);
                products.forEach(p => {
                    this.productsByName.set(p.name, Object.assign(Object.assign({}, p), { peripheralIds: [] }));
                    this.productsById.set(p.id, Object.assign(Object.assign({}, p), { peripheralIds: [] }));
                });
                this.firmwareVersion = fwVersions[0];
            }));
        });
    }
    getPrivateSimulations() {
        return __awaiter(this, void 0, void 0, function* () {
            const api = getContainer().get(ZaberApi);
            const chains = yield api.listVirtualDeviceChains();
            const results = yield Promise.all((chains !== null && chains !== void 0 ? chains : []).map((chain) => __awaiter(this, void 0, void 0, function* () {
                var _a, _b;
                return ({
                    id: chain.cloudId,
                    state: (_a = chain.state) !== null && _a !== void 0 ? _a : SimulationState.Error,
                    statusMessage: chain.statusMessage,
                    name: (_b = chain.name) !== null && _b !== void 0 ? _b : 'Unnamed',
                    type: SimulationType.Private,
                    error: chain.error,
                    expires: chain.expires,
                    components: yield Promise.all(chain.devices.map((device) => __awaiter(this, void 0, void 0, function* () {
                        const deviceId = device.deviceId;
                        const productName = yield this.getProductNameByDeviceId(deviceId);
                        const [imageUrl, manifest] = yield Promise.all([
                            getProductImageUrl(deviceId, api),
                            this.getProductModelManifest(productName)
                        ]);
                        return {
                            productName,
                            productImageUrl: imageUrl,
                            modelManifest: manifest,
                        };
                    }))),
                });
            })));
            return results;
        });
    }
    getPrivateSimulationStates() {
        return __awaiter(this, void 0, void 0, function* () {
            const api = getContainer().get(ZaberApi);
            const chains = yield api.listVirtualDeviceChains();
            const results = yield Promise.all((chains !== null && chains !== void 0 ? chains : []).map((chain) => __awaiter(this, void 0, void 0, function* () {
                var _a;
                return ({
                    id: chain.cloudId,
                    state: (_a = chain.state) !== null && _a !== void 0 ? _a : SimulationState.Error,
                    statusMessage: chain.statusMessage,
                    type: SimulationType.Private,
                    error: chain.error,
                    expires: chain.expires,
                });
            })));
            return results;
        });
    }
    getAvailableProductNames() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.productsByName.size === 0) {
                throw new Error('Product definitions have not been loaded yet or there was an error. Try refreshing the page.');
            }
            return Array.from(this.productsByName.keys());
        });
    }
    getSimulationData(simulationId, isPublic) {
        return __awaiter(this, void 0, void 0, function* () {
            var _a, _b;
            const api = getContainer().get(ZaberApi);
            const chain = yield api.getVirtualDeviceChain(simulationId, isPublic);
            return {
                id: chain.cloudId,
                state: (_a = chain.state) !== null && _a !== void 0 ? _a : SimulationState.Error,
                statusMessage: chain.statusMessage,
                name: (_b = chain.name) !== null && _b !== void 0 ? _b : 'Unnamed',
                type: isPublic ? SimulationType.Public : SimulationType.Private,
                expires: chain.expires,
                error: chain.error,
                components: yield Promise.all(chain.devices.map((device) => __awaiter(this, void 0, void 0, function* () {
                    const deviceId = device.deviceId;
                    const productName = yield this.getProductNameByDeviceId(deviceId);
                    return {
                        productName,
                        productImageUrl: yield getProductImageUrl(deviceId, api),
                        modelManifest: yield this.getProductModelManifest(productName),
                    };
                }))),
            };
        });
    }
    getSimulationStateData(simulationId, isPublic) {
        return __awaiter(this, void 0, void 0, function* () {
            var _a;
            const api = getContainer().get(ZaberApi);
            const chain = yield api.getVirtualDeviceChain(simulationId, isPublic);
            return {
                id: chain.cloudId,
                state: (_a = chain.state) !== null && _a !== void 0 ? _a : SimulationState.Error,
                statusMessage: chain.statusMessage,
                type: isPublic ? SimulationType.Public : SimulationType.Private,
                expires: chain.expires,
                error: chain.error,
            };
        });
    }
    getDeviceIdForProduct(productName) {
        return __awaiter(this, void 0, void 0, function* () {
            const productRecord = this.productsByName.get(productName);
            if (!productRecord) {
                throw new Error(`Invalid product name ${productName}`);
            }
            if (!productRecord.deviceId) {
                const api = getContainer().get(ZaberApi);
                const dbInfo = yield api.searchProduct({ nameLikeExpression: productName, fwVersion: this.firmwareVersion });
                // There should never be more than one result because we aren't allowing wildcards in
                // the product name at this point, and we're specifying the full FW version.
                if (dbInfo.length) {
                    productRecord.deviceId = dbInfo[0].deviceId;
                }
            }
            return productRecord.deviceId;
        });
    }
    createSimulation(productName, type) {
        return __awaiter(this, void 0, void 0, function* () {
            const deviceId = yield this.getDeviceIdForProduct(productName); // Currently everything has a device ID.
            this.checkCanCreateSimulationForProduct(productName);
            this.checkAuthorizedToCreateSimulationType(type);
            const api = getContainer().get(ZaberApi);
            const newDevice = yield api.createVirtualDeviceChain([{ deviceId, peripherals: [] }], `Virtual ${productName}`, 'Cloud front-end', type === SimulationType.Public);
            const simulationData = yield this.getSimulationData(newDevice.cloudId, type === SimulationType.Public);
            simulationData.state = SimulationState.Creating;
            return simulationData;
        });
    }
    createDaisyChainSimulation(simulationName, devices, type) {
        return __awaiter(this, void 0, void 0, function* () {
            this.checkAuthorizedToCreateSimulationType(type);
            const api = getContainer().get(ZaberApi);
            const newDevice = yield api.createVirtualDeviceChain(devices, simulationName, 'Cloud front-end', type === SimulationType.Public);
            const simulationData = yield this.getSimulationData(newDevice.cloudId, type === SimulationType.Public);
            simulationData.state = SimulationState.Creating;
            return simulationData;
        });
    }
    renameSimulation(simulationId, name) {
        return __awaiter(this, void 0, void 0, function* () {
            const api = getContainer().get(ZaberApi);
            yield api.renameVirtualDeviceChain(simulationId, name);
        });
    }
    removeSimulation(simulationId, isPublic) {
        return __awaiter(this, void 0, void 0, function* () {
            const api = getContainer().get(ZaberApi);
            yield api.deleteVirtualDeviceChain(simulationId, isPublic);
        });
    }
    turnOnSimulation(simulationId, isPublic) {
        return __awaiter(this, void 0, void 0, function* () {
            const api = getContainer().get(ZaberApi);
            yield api.virtualDevicePowerSwitch(simulationId, true, isPublic);
        });
    }
    turnOffSimulation(simulationId, isPublic) {
        return __awaiter(this, void 0, void 0, function* () {
            const api = getContainer().get(ZaberApi);
            yield api.virtualDevicePowerSwitch(simulationId, false, isPublic);
        });
    }
    checkCanCreateSimulationForProduct(productName) {
        if (!this.productsByName.has(productName)) {
            throw Error('Unsupported product');
        }
    }
    checkAuthorizedToCreateSimulationType(type) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        const authState = selectAuthState(getContainer().get(reduxStoreSymbol).getState());
        const isLoggedIn = authState === AuthState.Authenticated;
        if (isLoggedIn && type === SimulationType.Public) {
            throw Error('Logged in users cannot create public virtual devices');
        }
        if (!isLoggedIn && type === SimulationType.Private) {
            throw Error('You must be logged in to create a private virtual device');
        }
    }
    getProductNameByDeviceId(deviceId) {
        return __awaiter(this, void 0, void 0, function* () {
            var _a, _b;
            for (const product of this.productsById.values()) {
                if (product.deviceId === deviceId) {
                    return product.name;
                }
            }
            const dbInfo = yield this.getDeviceInfo(deviceId, this.firmwareVersion);
            return (_b = (_a = dbInfo === null || dbInfo === void 0 ? void 0 : dbInfo.device) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : 'unknown';
        });
    }
    getProductModelManifest(product) {
        return __awaiter(this, void 0, void 0, function* () {
            const cachedManifest = this.manifestCache.get(product);
            if (cachedManifest) {
                return cachedManifest;
            }
            const productInfo = this.productsByName.get(product);
            if (!productInfo) {
                throw Error(`Model manifest is not defined for the given product: ${product}`);
            }
            if (!productInfo.deviceId) {
                productInfo.deviceId = yield this.getDeviceIdForProduct(productInfo.name);
            }
            const deviceInfo = yield this.getDeviceInfo(productInfo.deviceId, this.firmwareVersion);
            const posRow = deviceInfo.settings.rows.find(r => r.name === 'pos');
            let motionType = MotionType.None;
            if (posRow) {
                const dimensionRow = deviceInfo.conversion_table.rows.find(r => r.contextual_dimension_id === posRow.contextual_dimension_id);
                if ((dimensionRow === null || dimensionRow === void 0 ? void 0 : dimensionRow.dimension_name) === 'Length') {
                    motionType = MotionType.Linear;
                }
                else if ((dimensionRow === null || dimensionRow === void 0 ? void 0 : dimensionRow.dimension_name) === 'Angle') {
                    motionType = MotionType.Rotary;
                }
            }
            if (motionType === MotionType.None) {
                throw new Error(`Could not determine motion type for product ${product}`);
            }
            const result = {
                fileName: productInfo.modelUrl,
                productName: productInfo.name,
                axes: [{ motionType }],
            };
            this.manifestCache.set(product, result);
            return result;
        });
    }
    getDeviceInfo(deviceID, fwVersion) {
        return __awaiter(this, void 0, void 0, function* () {
            // Cache access is not wrapped in a mutex because that slows down the initial page load, and it doesn't
            // matter if we fetch and store the same info twice because it will be identical (minor waste of bandwidth).
            const key = `${deviceID}-${fwVersion.major}.${fwVersion.minor}.${fwVersion.build}`;
            const cachedInfo = this.deviceInfoCache.get(key);
            if (cachedInfo) {
                return cachedInfo;
            }
            const api = getContainer().get(ZaberApi);
            const info = yield api.getDeviceInfo({ deviceID, fwVersion });
            this.deviceInfoCache.set(key, info);
            return info;
        });
    }
};
VirtualDeviceApi = __decorate([
    injectable()
], VirtualDeviceApi);
export { VirtualDeviceApi };
