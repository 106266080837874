import zlIcon from '../assets/zl_logo.svg?url';
import basicMovementIcon from '../assets/zl_app_icons/basic_movement_logo_red.svg?url';
import currentTunerIcon from '../assets/zl_app_icons/current_tuner_logo_red.svg?url';
import deviceIoIcon from '../assets/zl_app_icons/device_io_logo_red.svg?url';
import deviceSettingsIcon from '../assets/zl_app_icons/device_settings_logo_red.svg?url';
import firmwareUpdaterIcon from '../assets/zl_app_icons/firmware_updater_logo_red.svg?url';
import gcodeIcon from '../assets/zl_app_icons/gcode_logo_red.svg?url';
import hardwareModIcon from '../assets/zl_app_icons/hardware_mod_logo_red.svg?url';
import joystickIcon from '../assets/zl_app_icons/joystick_logo_red.svg?url';
import lc40Icon from '../assets/zl_app_icons/lc40_logo_red.svg?url';
import lockstepIcon from '../assets/zl_app_icons/lockstep_logo_red.svg?url';
import microscopeIcon from '../assets/zl_app_icons/microscope_logo_red.svg?url';
import oscilloscopeIcon from '../assets/zl_app_icons/oscilloscope_logo_red.svg?url';
import processControllerIcon from '../assets/zl_app_icons/process_controller_logo_red.svg?url';
import pvtIcon from '../assets/zl_app_icons/pvt_app_logo_red.svg?url';
import servoTunerIcon from '../assets/zl_app_icons/servo_tuner_logo_red.svg?url';
import terminalIcon from '../assets/zl_app_icons/terminal_logo_red.svg?url';
import triggersIcon from '../assets/zl_app_icons/triggers_logo_red.svg?url';
export const ZL_APP_ICONS = {
    Launcher: zlIcon,
    BasicMovement: basicMovementIcon,
    CurrentTuner: currentTunerIcon,
    DeviceIO: deviceIoIcon,
    DeviceSettings: deviceSettingsIcon,
    FirmwareUpdater: firmwareUpdaterIcon,
    GCode: gcodeIcon,
    HardwareMod: hardwareModIcon,
    Joystick: joystickIcon,
    LC40: lc40Icon,
    Lockstep: lockstepIcon,
    Microscope: microscopeIcon,
    Oscilloscope: oscilloscopeIcon,
    ProcessController: processControllerIcon,
    PVT: pvtIcon,
    ServoTuner: servoTunerIcon,
    Terminal: terminalIcon,
    Triggers: triggersIcon,
};
