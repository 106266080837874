import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { Text, TextArea, Button, Input } from '@zaber/react-library';
import { useSelector } from 'react-redux';
import { useActions } from '@zaber/toolbox/lib/redux';
import { Redirect } from 'react-router-dom';
import { browserHistory } from '../store/history';
import { PageUrls } from '../urls';
import { actions as userActionDefinitions } from '../user/actions';
import { selectLoggedInUserEmail, selectUserAccountDelete, IfAuth, AuthState } from '../user';
import { actions as feedbackActionDefinitions } from '../feedback/actions';
import { selectFeedbackText, selectFeedbackState } from '../feedback/selectors';
import { IconMessage } from '../components/IconMessage';
export const DeleteAccountConfirmation = () => {
    const userActions = useActions(userActionDefinitions);
    const feedbackActions = useActions(feedbackActionDefinitions);
    const userEmail = useSelector(selectLoggedInUserEmail);
    const { accountDeleteInProgress, accountDeleteError } = useSelector(selectUserAccountDelete);
    const [password, setPassword] = useState('');
    const [confirm, setConfirm] = useState(false);
    const [passwordError, setPasswordError] = useState('');
    const text = useSelector(selectFeedbackText);
    const { feedbackSubmissionInProgress } = useSelector(selectFeedbackState);
    useEffect(() => {
        setPassword('');
        setPasswordError(accountDeleteError ? 'password-error' : '');
    }, [accountDeleteError]);
    return (_jsxs(_Fragment, { children: [(!confirm || accountDeleteError) && _jsx(IfAuth, { desiredState: AuthState.NotAuthenticated, children: _jsx(Redirect, { to: PageUrls.Login }) }), _jsxs("div", { className: "delete-account-confirm", children: [_jsx(Text, { className: "farewell", t: Text.Type.H3, children: "We are sorry to see you go!" }), !(!accountDeleteInProgress && !accountDeleteError && confirm) && _jsx("div", { className: "keep-account", children: _jsx(Text, { e: Text.Emphasis.Light, onClick: () => browserHistory.goBack(), children: "Keep My Account" }) }), _jsxs("div", { className: "container", children: [_jsx(Text, { t: Text.Type.H4, className: "feedback", children: "Please let us know the reason why you are leaving and how we can improve our service:" }), _jsx(TextArea, { value: text, className: "textarea", "data-testid": "feedback-input", onValueChange: feedbackActions.setText }), _jsx(Button, { onClick: feedbackActions.sendFeedback, className: "feedback-button", color: "red", disabled: text === '' || feedbackSubmissionInProgress, children: "Submit Your Feedback" }), _jsx("br", {}), ((!accountDeleteInProgress && !confirm) || accountDeleteError) && _jsx(Text, { t: Text.Type.Body, children: "To confirm your decision, please re-enter your password:" }), _jsxs("div", { className: "password", children: [((!accountDeleteInProgress && !confirm) || accountDeleteError) && _jsxs(_Fragment, { children: [_jsx(Input, { "data-testid": "password-input", className: passwordError, type: "password", value: password, onChange: e => setPassword(e.target.value) }), _jsx(Button, { color: "red", disabled: !password || accountDeleteInProgress, onClick: () => {
                                                    userActions.deleteUserAccount(userEmail, password);
                                                    setConfirm(true);
                                                }, children: "Confirm" })] }), (!accountDeleteInProgress && !accountDeleteError && confirm) && _jsxs(_Fragment, { children: [_jsx(IconMessage, { icon: "Confirmation", color: "green", className: "status", children: "Confirmed" }), _jsx(IfAuth, { desiredState: AuthState.NotAuthenticated, children: _jsx(Redirect, { to: PageUrls.VirtualDeviceHome }) })] }), accountDeleteInProgress && _jsx(IconMessage, { icon: "Gear", className: "status", rotatingIcon: true, children: "Updating..." })] }), accountDeleteError && _jsx(IconMessage, { icon: "ErrorWarning", color: "red", children: "Password is incorrect" })] })] })] }));
};
