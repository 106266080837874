import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { AnimationClasses, Button, EditableField, Icons, Text } from '@zaber/react-library';
import { selectChainBuilder, selectCreationRetryInfo, selectIsFinalizingChain } from '../virtual_device/selectors';
import UnknownDeviceImage from '../assets/unknown_device.svg';
import { useActions } from '../utils';
import { virtualDeviceActionDefinitions, VirtualDeviceProductSelector } from '../virtual_device';
const MAX_DEVICES = 5;
const Header = ({ name, numComponents }) => {
    const actions = useActions(virtualDeviceActionDefinitions);
    const isFinalizing = useSelector(selectIsFinalizingChain);
    const isRetrying = useSelector(selectCreationRetryInfo) === 'chain';
    return _jsxs(_Fragment, { children: [_jsx("div", { className: "expander first heading-item" }), _jsx("div", { className: "power-switch heading-item", children: _jsxs(Text, { e: Text.Emphasis.Bold, children: ["Devices (", numComponents, ")"] }) }), _jsx(EditableField, { className: "heading-item", disabled: isFinalizing || isRetrying, value: name, validate: [
                    EditableField.validators.notEmpty,
                    EditableField.validators.maxLength(30),
                    EditableField.validators.notWhitespace,
                    EditableField.validators.noControl
                ], onValueChange: val => {
                    if (val !== name) {
                        actions.chainBuilderRename(val);
                    }
                } }), _jsx("div", { className: "heading-item cloud-id", children: "\u00A0" }), _jsx("div", { className: "heading-item" }), _jsx("div", { className: "remove last heading-item", children: _jsx(Icons.Trash, { title: "Cancel Chain Creation", appearsClickable: true, disabled: isFinalizing, onClick: () => actions.chainBuilderCancel() }) })] });
};
const Row = ({ index, device }) => {
    const actions = useActions(virtualDeviceActionDefinitions);
    const isFinalizing = useSelector(selectIsFinalizingChain);
    const isRetrying = useSelector(selectCreationRetryInfo) === 'chain';
    return _jsxs(_Fragment, { children: [_jsx("div", { className: "device-type first row-item delete-device", children: _jsx(Icons.Cross, { appearsClickable: true, disabled: isFinalizing || isRetrying, title: "Remove this device", onClick: () => actions.chainBuilderRemoveDevice(index) }) }), _jsxs("div", { className: "device-type product-image row-item", children: [device.image && _jsx("img", { className: "product-image", src: device.image, alt: "Thumbnail" }), !device.image && _jsx(UnknownDeviceImage, {})] }), _jsxs("div", { className: "device-type device-name row-item", children: [!!device.params.deviceId && _jsx(Text, { "data-testid": "selected-product-name", children: device.productName }), (!device.params.deviceId) &&
                        _jsx(VirtualDeviceProductSelector, { disabled: isFinalizing || isRetrying, onValueChange: val => actions.chainBuilderSelectDevice(index, val) })] }), _jsx("div", { className: "device-type row-item" }), _jsx("div", { className: "device-type last row-item" })] });
};
const Footer = () => {
    var _a, _b, _c;
    const actions = useActions(virtualDeviceActionDefinitions);
    const builder = useSelector(selectChainBuilder);
    const isFinalizing = useSelector(selectIsFinalizingChain);
    const isRetrying = useSelector(selectCreationRetryInfo) === 'chain';
    const determined = _.sumBy((_a = builder.devices) !== null && _a !== void 0 ? _a : [], d => d.params.deviceId !== 0 ? 1 : 0);
    const canFinalize = determined > 0 && determined <= MAX_DEVICES;
    const canAdd = ((_c = (_b = builder.devices) === null || _b === void 0 ? void 0 : _b.length) !== null && _c !== void 0 ? _c : 0) < MAX_DEVICES;
    return _jsxs("div", { className: "device-type first last row-item chain-footer", children: [!isRetrying && _jsxs(Button, { color: "grey", disabled: !canAdd || isFinalizing || isRetrying, title: canAdd ? undefined : 'Simulated device chains are currently limited to five devices.', onClick: actions.chainBuilderAddDevice, children: [_jsx(Icons.Plus, { className: "embiggen-me" }), "Add Device"] }), !isRetrying && _jsxs(Button, { color: "grey", disabled: !canFinalize || isFinalizing || isRetrying, "data-testid": "finalize-chain-button", onClick: () => actions.chainBuilderFinalize(), children: [_jsx(Icons.Tick, { className: "embiggen-me" }), "Finalize Device Chain"] }), !isFinalizing && isRetrying && _jsxs(Button, { color: "red", title: "Cancel device chain creation retry", onClick: actions.cancelCreateRetry, children: [_jsx(Icons.Cross, { className: "embiggen-me" }), "Cancel"] }), isRetrying && _jsxs(_Fragment, { children: [_jsx(Icons.ErrorWarning, { color: "orange" }), "\u00A0", _jsx(Text, { children: "Device creation delayed due to high system load. Will automatically retry." })] }), isFinalizing && !isRetrying && _jsxs(_Fragment, { children: [_jsx(Icons.Gear, { className: AnimationClasses.Rotation }), _jsx(Text, { children: "Creating device chain..." })] })] });
};
export const ChainBuilder = () => {
    var _a, _b, _c;
    const builder = useSelector(selectChainBuilder);
    return (_jsxs(_Fragment, { children: [_jsx(Header, { name: builder.name, numComponents: (_b = (_a = builder.devices) === null || _a === void 0 ? void 0 : _a.length) !== null && _b !== void 0 ? _b : 0 }), (_c = builder.devices) === null || _c === void 0 ? void 0 : _c.map((device, i) => _jsx(Row, { index: i, device: device }, i)), _jsx(Footer, {})] }));
};
