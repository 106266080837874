import { all, call, put, race, select, take, takeEvery, takeLatest, takeLeading } from 'redux-saga/effects';
import { LOCATION_CHANGE, routerActions } from 'connected-react-router';
import { MovementFailedException, MovementInterruptedException } from '@zaber/motion';
import { getMessageFromError, Log, ZaberApi } from '../app_components';
import { getContainer } from '../container';
import { selectRouterLocation } from '../navigation_bar/selectors';
import { createSimulationViewerSimulationIdUrl, PageUrls } from '../urls';
import { userIsLoggedIn } from '../user';
import { ActionTypes as UserActionTypes } from '../user/actions';
import { SimulationType, SimulationMoveTypes, SimulationState, VirtualDeviceApi } from '../virtual_device_api';
import { extendKey, makeRootKey } from '../virtual_device/keys';
import { getDefaultDimension, getDefaultDimensionUnits } from '../units';
import { throwIfJsError } from '../utils';
import { actions as virtualDeviceActions, ActionTypes as VirtualDeviceActionTypes } from '../virtual_device/actions';
import { getAsciiConnection, getAxis, getSimulationData, waitForMessageRouterToBeReady, waitForSimulationDataToLoad } from '../virtual_device/sagas';
import { locationToPosition } from '../virtual_device/types';
import { createIotSubscriptionEventChannel } from '../virtual_device_api/iot_subscription';
import { selectIotRealm } from '../user/selectors';
import { selectAllAxes, selectAllSimulations } from '../virtual_device/selectors';
import { actions, ActionTypes } from './actions';
import { AxisWarningFlagType, warningFlagMessages, warningFlagToErrorType } from './types';
import { selectCurrentSimulation } from './selectors';
let logger;
export function* virtualDeviceViewerSaga() {
    logger = getContainer().get(Log).getLogger('virtualDeviceViewerSaga');
    yield all([
        takeEvery(UserActionTypes.AUTH_CHANGED, onUserAuthChange),
        takeEvery(LOCATION_CHANGE, onPageUrlChange),
        takeEvery(VirtualDeviceActionTypes.SIMULATION_CREATED, onSimulationCreated),
        takeEvery(VirtualDeviceActionTypes.UPDATE_SIMULATION_STATE, onSimulationStateChange),
        takeLatest(ActionTypes.CHECK_SIMULATION, checkSimulation),
        takeLatest(ActionTypes.SETUP_SIMULATION, setupSimulation),
        takeEvery(ActionTypes.MOVE_SIMULATION_AXIS, moveAxis),
        takeLeading(ActionTypes.START_MONITORING_SIMULATION_POS, monitorSimulation),
    ]);
}
function* onUserAuthChange({ payload: { isInitialCheck } }) {
    if (isInitialCheck) {
        return;
    }
    const routerLocation = yield select(selectRouterLocation);
    if (routerLocation.pathname === PageUrls.VirtualDeviceViewer) {
        yield put(actions.stopMonitoringSimulationPos());
        yield put(routerActions.push(PageUrls.VirtualDeviceViewer));
    }
}
function* onSimulationCreated({ payload: { simulation } }) {
    const routerLocation = yield select(selectRouterLocation);
    if ((simulation === null || simulation === void 0 ? void 0 : simulation.id) && routerLocation.pathname === PageUrls.VirtualDeviceViewer && simulation.type === SimulationType.Public) {
        yield put(routerActions.push(createSimulationViewerSimulationIdUrl(simulation.id)));
    }
}
function* checkSimulation({ payload: { simulationId } }) {
    yield call(userIsLoggedIn);
    yield call(waitForSimulationDataToLoad);
    const virtualDeviceApi = getContainer().get(VirtualDeviceApi);
    const simulations = yield select(selectAllSimulations);
    const existingSimulation = simulations === null || simulations === void 0 ? void 0 : simulations.find(sim => sim.id === simulationId);
    try {
        const isLoggedIn = yield call(userIsLoggedIn);
        const simulationData = yield call([virtualDeviceApi, virtualDeviceApi.getSimulationData], simulationId, !isLoggedIn);
        yield put(virtualDeviceActions.storeSimulationMetadata(simulationData));
        yield put(actions.simulationCheckDone(simulationId));
        if (simulationData.state === SimulationState.On) {
            yield put(actions.setupSimulation(simulationId));
        }
    }
    catch (e) {
        throwIfJsError(e);
        const errorMessage = getMessageFromError(e);
        if (existingSimulation) {
            yield put(actions.simulationCheckErr(`You no longer have access to simulation with ID "${simulationId}". ${errorMessage}`));
        }
        else {
            yield put(actions.simulationCheckErr(errorMessage));
        }
    }
}
function* onSimulationStateChange({ payload: { simulationId, newState } }) {
    const currentSimulation = yield select(selectCurrentSimulation);
    if (currentSimulation && currentSimulation.id === simulationId && newState === SimulationState.On) {
        yield put(actions.setupSimulation(simulationId));
    }
}
function* setupSimulation({ payload: { simulationId } }) {
    yield put(actions.stopMonitoringSimulationPos());
    try {
        // The status may say that it is turned on but the message router may not be ready to receive
        // connections at this point. So, we need to make sure it is ready first
        yield call(waitForMessageRouterToBeReady, simulationId);
        const simulationDevices = yield call(getSimulationDevices, simulationId);
        yield call(storeSimulationDeviceAndAxisData, simulationId, simulationDevices);
        for (const device of simulationDevices) {
            yield call(loadExtraSimulationData, device.identity.deviceId);
        }
        yield put(actions.startMonitoringSimulationPos(simulationId));
        yield put(actions.simulationSetupDone(simulationId));
    }
    catch (e) {
        yield put(actions.stopMonitoringSimulationPos());
        yield put(actions.simulationSetupErr(simulationId, getMessageFromError(e)));
    }
}
function* getSimulationDevices(simulationId) {
    const asciiConnection = yield call(getAsciiConnection, simulationId);
    const devices = yield call([asciiConnection, asciiConnection.detectDevices], { identifyDevices: true });
    if (devices.length < 1) {
        throw Error('No devices found on the simulation');
    }
    devices.sort((device1, device2) => device1.deviceAddress - device2.deviceAddress);
    return devices;
}
function* storeSimulationDeviceAndAxisData(simulationId, simulationDevices) {
    const simulationKey = makeRootKey(simulationId);
    const deviceStates = [];
    const axisStates = [];
    for (const device of simulationDevices) {
        const deviceKey = extendKey(simulationKey, device.deviceAddress);
        const axisKeys = [];
        for (let i = 0; i < device.identity.axisCount; i++) {
            const axis = device.getAxis(i + 1);
            const axisKey = extendKey(deviceKey, axis.axisNumber);
            axisKeys.push(axisKey);
            // TODO (Soleil 2021-09-21): We should send an IoT message to the simulation manager to
            // get it to send us the initial location of the carriage. (Ticket DT-77).
            const positionDimension = getDefaultDimension(axis.axisType);
            if (positionDimension) {
                const positionDefaultUnits = getDefaultDimensionUnits(positionDimension);
                const conversionFactor = yield call([axis.settings, axis.settings.convertFromNativeUnits], 'pos', 1, positionDefaultUnits);
                let resolution;
                try {
                    resolution = yield call([axis.settings, axis.settings.get], 'resolution');
                }
                catch (e) {
                    throwIfJsError(e);
                    resolution = 1;
                }
                axisStates.push({
                    key: axisKey,
                    axisNumber: axis.axisNumber,
                    identity: axis.identity,
                    location: 0,
                    resolution,
                    locationDimension: positionDimension,
                    locationNativeToDefaultUnitScale: conversionFactor,
                });
            }
            else {
                axisStates.push({
                    key: axisKey,
                    axisNumber: axis.axisNumber,
                    identity: axis.identity,
                    location: 0,
                });
            }
            const warnings = yield call([axis.warnings, axis.warnings.getFlags]);
            yield put(actions.storeAxisWarningFlags(axisKey, Array.from(warnings).map(flag => ({
                type: warningFlagToErrorType(flag),
                warningFlag: flag,
                message: warningFlagMessages[flag],
            }))));
        }
        deviceStates.push({
            key: deviceKey,
            address: device.deviceAddress,
            identity: device.identity,
            axes: axisKeys
        });
    }
    yield put(virtualDeviceActions.updateSimulationDevicesAndAxes(simulationId, deviceStates, axisStates));
}
function* loadExtraSimulationData(deviceId) {
    try {
        const api = getContainer().get(ZaberApi);
        const productData = yield call([api, api.getWebsiteDataForProducts], [deviceId]);
        yield put(actions.websiteProductDataLoaded(deviceId, productData[0]));
    }
    catch (e) {
        throwIfJsError(e);
        logger.warn(e);
    }
}
function* onPageUrlChange({ payload: { location } }) {
    if (location.pathname !== PageUrls.VirtualDeviceViewer) {
        return;
    }
    const queryParse = new URLSearchParams(location.search);
    const newSimulationId = queryParse.get('simulationid');
    const newProduct = queryParse.get('product');
    if (newSimulationId) {
        yield put(actions.checkSimulation(newSimulationId));
    }
    else if (newProduct) {
        yield put(virtualDeviceActions.createSimulation(newProduct, SimulationType.Public));
    }
    else {
        yield put(actions.resetState());
    }
}
function* moveAxis({ payload: { axisKey, type } }) {
    try {
        const axis = yield call(getAxis, axisKey);
        switch (type) {
            case SimulationMoveTypes.Home:
                yield call([axis, axis.home]);
                break;
            case SimulationMoveTypes.ToMax:
                yield call([axis, axis.moveMax]);
                break;
            case SimulationMoveTypes.ToMin:
                yield call([axis, axis.moveMin]);
                break;
            case SimulationMoveTypes.Stop:
                yield call([axis, axis.stop]);
                break;
        }
    }
    catch (e) {
        throwIfJsError(e);
        if (e instanceof MovementInterruptedException || e instanceof MovementFailedException) {
            yield put(actions.storeAxisWarningFlags(axisKey, e.details.warnings.map(flag => ({
                type: warningFlagToErrorType(flag),
                warningFlag: flag,
                message: getMessageFromError(e),
            }))));
        }
        else {
            yield put(actions.storeAxisWarningFlags(axisKey, [{
                    type: AxisWarningFlagType.Unknown,
                    message: getMessageFromError(e),
                }]));
        }
    }
}
function* monitorSimulation({ payload: { simulationId } }) {
    let realm = 'unauthenticated';
    const isLoggedIn = yield call(userIsLoggedIn);
    if (isLoggedIn) {
        realm = yield select(selectIotRealm);
    }
    const simulation = yield call(getSimulationData, simulationId);
    const axisStates = yield select(selectAllAxes);
    const posChannel = createIotSubscriptionEventChannel(`${realm}/simulation/${simulationId}/loc`, true, true);
    try {
        while (true) {
            const { data } = yield race({
                data: take(posChannel),
                stop: take(ActionTypes.STOP_MONITORING_SIMULATION_POS),
            });
            if (!data) {
                break;
            }
            const { message, error /*, connected */ } = data;
            if (error) {
                if (String(error).includes('has been closed')) {
                    logger.warn(error);
                }
                else {
                    logger.error(error);
                }
            }
            // TODO (Soleil 2021-09-22) Related to DT-77, if connected is true here we should poke the
            // simulation manager to send a position update, because it means the IoT connection
            // may have been dropped and reconnected or the connection has just been established.
            // if (connected) {
            //   yield call(something);
            // }
            if (message) {
                // TODO (Soleil 2021-09-15): We are converting FW locations to positions here and ignoring
                // the difference for the rest of the front end, but really location should be used to
                // drive the animation (after conversion to model units), and position (for the numeric
                // display if desired) should be obtained separately by polling the device.
                // Also note that we can't put bigints in the Redux store - it causes a serialization error.
                const newLocations = message.payload.toString().split(',').map(s => BigInt(s));
                const newPositions = newLocations.map((loc, i) => {
                    var _a, _b;
                    let resolution = 1;
                    if ((simulation === null || simulation === void 0 ? void 0 : simulation.axes) && axisStates) {
                        resolution = (_b = (_a = axisStates[simulation.axes[i]]) === null || _a === void 0 ? void 0 : _a.resolution) !== null && _b !== void 0 ? _b : 1;
                    }
                    return locationToPosition(loc, resolution);
                });
                yield put(virtualDeviceActions.updateSimulationAxisLocations(simulationId, newPositions));
            }
        }
    }
    finally {
        posChannel.close();
    }
}
