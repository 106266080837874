var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { ReactShakaPlayer } from '@mkhuda/react-shaka-player';
import { WebVTTParser } from 'webvtt-parser';
import { NoticeBanner, Text } from '@zaber/react-library';
import { duration, utc } from 'moment';
import { getMessageFromError } from '../app_components';
export const VideoPlayer = ({ videoUrl, captionsUrl = null, chaptersUrl = null }) => {
    const [mainPlayer, setMainPlayer] = useState(null);
    const [chapters, setChapters] = useState(null);
    const [videoError, setVideoError] = useState(null);
    useEffect(() => {
        if (!mainPlayer) {
            return;
        }
        const { player, videoElement } = mainPlayer;
        function load() {
            return __awaiter(this, void 0, void 0, function* () {
                try {
                    yield player.load(videoUrl);
                }
                catch (err) {
                    setVideoError(getMessageFromError(err));
                }
                if (captionsUrl) {
                    try {
                        yield player.addTextTrackAsync(captionsUrl, 'en', 'captions');
                    }
                    catch (err) {
                        setVideoError(getMessageFromError(err));
                    }
                }
                if (chaptersUrl) {
                    try {
                        yield player.addChaptersTrack(chaptersUrl, 'en');
                    }
                    catch (err) {
                        setVideoError(getMessageFromError(err));
                    }
                }
            });
        }
        if (player && videoElement) {
            void load();
        }
    }, [mainPlayer]);
    useEffect(() => {
        if (!chaptersUrl) {
            return;
        }
        (() => __awaiter(void 0, void 0, void 0, function* () {
            const response = yield fetch(chaptersUrl, { mode: 'cors' });
            const content = yield response.text();
            const parser = new WebVTTParser();
            const chapters = parser.parse(content);
            setChapters(chapters);
        }))().catch(err => {
            setVideoError(getMessageFromError(err));
        });
    }, [chaptersUrl]);
    const seek = (time) => {
        if ((mainPlayer === null || mainPlayer === void 0 ? void 0 : mainPlayer.videoElement) && mainPlayer.player) {
            mainPlayer.videoElement.currentTime = mainPlayer.player.seekRange().start + time;
        }
    };
    return _jsx("div", { className: "video-player-container", children: _jsxs("div", { className: "video-player", children: [videoError && _jsxs(NoticeBanner, { children: ["There was an error loading the video: ", videoError] }), videoError == null && _jsx(ReactShakaPlayer, { className: "youtube-theme", config: {
                        streaming: {
                            bufferingGoal: 30,
                            rebufferingGoal: 15,
                            bufferBehind: 30,
                            retryParameters: {
                                maxAttempts: 3,
                                baseDelay: 500,
                                backoffFactor: 2,
                            },
                            failureCallback: (error) => {
                                // eslint-disable-next-line no-console
                                console.warn('Player error:', error);
                            }
                        }
                    }, uiConfig: {
                        // TODO: For some reason the chapter control name is not recognzied.
                        //controlPanelElements: ['play_pause', 'time_and_duration', 'spacer', 'volume', 'chapter', 'fullscreen', 'overflow_menu'],
                        overflowMenuButtons: ['quality', 'captions', 'picture_in_picture', 'playback_rate'],
                        seekBarColors: {
                            base: 'rgba(255,255,255,.2)',
                            buffered: 'rgba(255,255,255,.4)',
                            played: 'rgb(255,0,0)',
                        },
                    }, onLoad: player => setMainPlayer(player) }), chapters && videoError == null && _jsx("div", { className: "chapters", children: chapters.cues.map((cue, i) => _jsxs("div", { children: [_jsx(Text, { t: Text.Type.H5, appearsClickable: true, onClick: () => seek(cue.startTime), children: utc(duration(cue.startTime, 'seconds').asMilliseconds()).format('mm:ss') }), "\u2003", _jsx(Text, { children: cue.text })] }, i)) })] }) });
};
