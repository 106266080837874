import { all, call, fork, put, race, delay, select, take, takeEvery, takeLatest, takeLeading } from 'redux-saga/effects';
import { throwIfJsError } from '../utils';
import { getContainer } from '../container';
import { userIsLoggedIn } from '../user';
import { SimulationState, VirtualDeviceApi, SimulationType, getProductImageUrl, } from '../virtual_device_api';
import { VirtualDevicesService } from '../virtual_device_connection';
import { getHttpCodeFromError, getMessageFromError, Log, ZaberApi } from '../app_components';
import { selectIotRealm } from '../user/selectors';
import { actions, ActionTypes } from './actions';
import { selectAllSimulations, selectChainBuilder, selectCreatingSimulations, selectCreationRetryInfo, selectIsPollingLocked, selectPrivateSimulations, selectPublicSimulation, selectTurnOnRetryInfo } from './selectors';
import { EntityKeyType, getKeyData } from './keys';
import { pollIntervalsForSimState, DEFAULT_POLL_INTERVAL, RETRY_INTERVAL } from './types';
let statusLogger;
export function* virtualDeviceSaga() {
    yield all([
        takeEvery(ActionTypes.LOAD_SIMULATION_PRODUCTS, loadSimulationProducts),
        takeEvery(ActionTypes.LOAD_PRIVATE_SIMULATIONS, loadPrivateSimulations),
        takeEvery(ActionTypes.CREATE_SIMULATION, createSimulation),
        takeEvery(ActionTypes.RENAME_SIMULATION, renameSimulation),
        takeEvery(ActionTypes.REMOVE_SIMULATION, removeSimulation),
        takeEvery(ActionTypes.TURN_ON_SIMULATION, turnOnSimulation),
        takeEvery(ActionTypes.TURN_OFF_SIMULATION, turnOffSimulation),
        takeEvery(ActionTypes.CHAIN_BUILDER_SELECT_DEVICE, chainBuilderSelectDevice),
        takeEvery(ActionTypes.CHAIN_BUILDER_FINALIZE, chainBuilderFinalize),
        takeLatest(ActionTypes.START_MONITORING, startMonitoring),
        takeLatest(ActionTypes.RETRY_CREATE_SIMULATION, retryCreate),
        takeLatest(ActionTypes.CHAIN_BUILDER_RETRY_FINALIZE, retryCreate),
        takeLeading(ActionTypes.RETRY_TURN_ON, retryTurnOn),
    ]);
}
function* loadPrivateSimulations() {
    const isLoggedIn = yield call(userIsLoggedIn);
    if (!isLoggedIn) {
        yield put(actions.privateSimulationsLoaded([]));
        return;
    }
    const virtualDeviceApi = getContainer().get(VirtualDeviceApi);
    try {
        yield put(actions.setPollLock(true));
        yield call([virtualDeviceApi, virtualDeviceApi.loadProductDefinitions]);
        const privateSimulations = yield call([virtualDeviceApi, virtualDeviceApi.getPrivateSimulations]);
        yield put(actions.privateSimulationsLoaded(privateSimulations));
    }
    catch (e) {
        throwIfJsError(e);
        yield put(actions.privateSimulationsLoadedErr(getMessageFromError(e)));
    }
    finally {
        yield put(actions.setPollLock(false));
    }
}
function* loadSimulationProducts() {
    const virtualDeviceApi = getContainer().get(VirtualDeviceApi);
    try {
        yield call([virtualDeviceApi, virtualDeviceApi.loadProductDefinitions]);
        const publicSimulationProducts = yield call([virtualDeviceApi, virtualDeviceApi.getAvailableProductNames]);
        yield put(actions.simulationProductsLoaded(publicSimulationProducts));
    }
    catch (e) {
        throwIfJsError(e);
        yield put(actions.simulationProductsLoadErr(getMessageFromError(e)));
    }
}
function* createSimulation({ payload: { productName, type } }) {
    if (!productName) {
        return;
    }
    const virtualDeviceApi = getContainer().get(VirtualDeviceApi);
    try {
        const newSimulation = yield call([virtualDeviceApi, virtualDeviceApi.createSimulation], productName, type);
        yield put(actions.creatingSimulation(newSimulation));
    }
    catch (e) {
        throwIfJsError(e);
        if (getHttpCodeFromError(e) === 503) {
            yield put(actions.retryCreateSimulation(productName, type));
        }
        yield put(actions.simulationCreationErr(getMessageFromError(e)));
    }
    // TODO (DT-194) Locally cache (or cookies?) the ID of the last public simulation the user started so that
    // the simulation shows up automatically when the user returns to the page and as long as the
    // simulation trial period has not expired
}
function* renameSimulation({ payload: { simulationId, name } }) {
    const virtualDeviceApi = getContainer().get(VirtualDeviceApi);
    try {
        yield call([virtualDeviceApi, virtualDeviceApi.renameSimulation], simulationId, name);
        yield put(actions.updateSimulationName(simulationId, name));
    }
    catch (e) {
        throwIfJsError(e);
        yield put(actions.storeRenameError('Name must be 1-30 visible characters.'));
    }
}
function* removeSimulation({ payload: { simulationId } }) {
    yield put(actions.setPollLock(true));
    try {
        const virtualDeviceApi = getContainer().get(VirtualDeviceApi);
        const isLoggedIn = yield call(userIsLoggedIn);
        try {
            yield call([virtualDeviceApi, virtualDeviceApi.removeSimulation], simulationId, !isLoggedIn);
        }
        catch (e) {
            throwIfJsError(e);
            yield put(actions.updateSimulationError(simulationId, getMessageFromError(e)));
        }
    }
    finally {
        yield put(actions.setPollLock(false));
    }
}
function* turnOnSimulation({ payload: { simulationId } }) {
    yield put(actions.setPollLock(true));
    try {
        yield put(actions.cancelRetryTurnOn(simulationId));
        yield put(actions.clearSimulationError(simulationId));
        yield put(actions.updateSimulationState(simulationId, SimulationState.TurningOn));
        const virtualDeviceApi = getContainer().get(VirtualDeviceApi);
        const isLoggedIn = yield call(userIsLoggedIn);
        try {
            yield call([virtualDeviceApi, virtualDeviceApi.turnOnSimulation], simulationId, !isLoggedIn);
        }
        catch (e) {
            throwIfJsError(e);
            if (getHttpCodeFromError(e) === 503) {
                yield put(actions.retryTurnOn(simulationId));
            }
            yield put(actions.updateSimulationError(simulationId, getMessageFromError(e)));
        }
    }
    finally {
        yield put(actions.setPollLock(false));
    }
}
function* turnOffSimulation({ payload: { simulationId } }) {
    yield put(actions.setPollLock(true));
    try {
        yield put(actions.clearSimulationError(simulationId));
        yield put(actions.updateSimulationState(simulationId, SimulationState.TurningOff));
        const virtualDeviceApi = getContainer().get(VirtualDeviceApi);
        const isLoggedIn = yield call(userIsLoggedIn);
        try {
            yield call([virtualDeviceApi, virtualDeviceApi.turnOffSimulation], simulationId, !isLoggedIn);
        }
        catch (e) {
            throwIfJsError(e);
            yield put(actions.updateSimulationError(simulationId, getMessageFromError(e)));
        }
    }
    finally {
        yield put(actions.setPollLock(false));
    }
}
export function* waitForSimulationDataToLoad() {
    const simulations = yield select(selectAllSimulations);
    if (simulations === null) {
        yield all([
            take(ActionTypes.PRIVATE_SIMULATIONS_LOADED),
            take(ActionTypes.SIMULATION_PRODUCTS_LOADED),
        ]);
    }
}
export function* getSimulationData(simulationId) {
    var _a;
    const simulations = yield select(selectAllSimulations);
    return (_a = simulations === null || simulations === void 0 ? void 0 : simulations.find(sim => sim.id === simulationId)) !== null && _a !== void 0 ? _a : null;
}
export function* getAsciiConnection(simulationId) {
    let realm = 'unauthenticated';
    const isLoggedIn = yield call(userIsLoggedIn);
    if (isLoggedIn) {
        realm = yield select(selectIotRealm);
    }
    const virtualDevicesService = getContainer().get(VirtualDevicesService);
    const connection = yield call([virtualDevicesService, virtualDevicesService.getAsciiConnection], simulationId, realm);
    return connection;
}
export function* getDevice(deviceKey) {
    const keyData = getKeyData(deviceKey);
    const simulationId = keyData[EntityKeyType.SIMULATION];
    const deviceAddress = Number(keyData[EntityKeyType.DEVICE]);
    if (!simulationId || !Number.isFinite(deviceAddress)) {
        throw Error(`Invalid data in device entity key: ${deviceKey}`);
    }
    const asciiConnection = yield call(getAsciiConnection, simulationId);
    return asciiConnection.getDevice(deviceAddress);
}
export function* getAxis(axisKey) {
    const keyData = getKeyData(axisKey);
    const simulationId = keyData[EntityKeyType.SIMULATION];
    const deviceAddress = Number(keyData[EntityKeyType.DEVICE]);
    const axisNumber = Number(keyData[EntityKeyType.AXIS]);
    if (!simulationId || !Number.isFinite(deviceAddress) || !Number.isFinite(axisNumber)) {
        throw Error(`Invalid data in axis entity key: ${axisKey}`);
    }
    const asciiConnection = yield call(getAsciiConnection, simulationId);
    const device = asciiConnection.getDevice(deviceAddress);
    return device.getAxis(axisNumber);
}
export function* waitForMessageRouterToBeReady(simulationId, maxAttempts = 10) {
    for (let attempt = 0; attempt < maxAttempts; attempt++) {
        try {
            yield call(getAsciiConnection, simulationId);
            break;
        }
        catch (e) {
            if (attempt === maxAttempts - 1) {
                throw e;
            }
            yield delay(1000);
        }
    }
}
function* startMonitoring() {
    statusLogger = getContainer().get(Log).getLogger('virtualDeviceMonitorSaga');
    yield fork(monitorSimulationStatus);
}
function* monitorSimulationStatus() {
    statusLogger.info('Device status monitoring started.');
    yield race({
        poll: call(pollDeviceStates),
        stop: take(ActionTypes.STOP_MONITORING),
    });
    statusLogger.info('Device status monitoring stopped.');
}
function* pollDeviceStates() {
    let isLoggedIn = yield call(userIsLoggedIn);
    const virtualDeviceApi = getContainer().get(VirtualDeviceApi);
    yield call([virtualDeviceApi, virtualDeviceApi.loadProductDefinitions]);
    const newSimsAlreadySeen = new Set();
    while (true) {
        let locked = yield select(selectIsPollingLocked);
        while (locked) {
            yield take(ActionTypes.SET_POLL_LOCK);
            locked = yield select(selectIsPollingLocked);
        }
        // Get current in-memory states of all known simulations.
        isLoggedIn = yield call(userIsLoggedIn);
        const prevStates = {};
        let publicSimId;
        if (isLoggedIn) {
            const privateSims = yield select(selectPrivateSimulations);
            for (const activeSim of privateSims !== null && privateSims !== void 0 ? privateSims : []) {
                prevStates[activeSim.id] = activeSim;
            }
        }
        else {
            const publicSim = yield select(selectPublicSimulation);
            if (publicSim) {
                prevStates[publicSim.id] = publicSim;
                publicSimId = publicSim.id;
            }
        }
        // Get current states of all sims from back end.
        let currentStates = [];
        const events = [];
        const hadErrors = new Set();
        if (isLoggedIn) {
            currentStates = yield call([virtualDeviceApi, virtualDeviceApi.getPrivateSimulationStates]);
        }
        else if (publicSimId) {
            try {
                const currentState = yield call([virtualDeviceApi, virtualDeviceApi.getSimulationStateData], publicSimId, true);
                if (currentState) {
                    currentStates.push(currentState);
                }
            }
            catch (e) {
                throwIfJsError(e);
                statusLogger.error(`Unhandled error polling free trial simulation ${publicSimId}`, e);
                events.push(actions.updateSimulationError(publicSimId, `There was an error while checking the state of this Virtual Device: ${getMessageFromError(e)}`));
                hadErrors.add(publicSimId);
            }
        }
        // Check on any simulations being created.
        const beingCreated = yield select(selectCreatingSimulations);
        for (const newSim of beingCreated) {
            if (typeof newSim === 'object') {
                try {
                    const createdSim = yield call([virtualDeviceApi, virtualDeviceApi.getSimulationStateData], newSim.id, !isLoggedIn);
                    if ((createdSim === null || createdSim === void 0 ? void 0 : createdSim.type) === SimulationType.Public) {
                        currentStates.push(createdSim);
                    }
                }
                catch (e) {
                    throwIfJsError(e);
                    statusLogger.error(`Unhandled error polling in-creation simulation ${newSim.id}`, e);
                    events.push(actions.updateSimulationError(newSim.id, `There was an error while creating Virtual Device: ${getMessageFromError(e)}`));
                    hadErrors.add(newSim.id);
                }
            }
        }
        let pollInterval;
        // Compare current to previous states and generate update events.
        for (const currentState of currentStates) {
            pollInterval = pollInterval
                ? Math.min(pollInterval, pollIntervalsForSimState[currentState.state])
                : pollIntervalsForSimState[currentState.state];
            if (currentState.id in hadErrors) {
                continue; // Already emitted an error for this simulation.
            }
            else if (currentState.id in prevStates) {
                newSimsAlreadySeen.delete(currentState.id);
                const prevState = prevStates[currentState.id];
                // Emit event if state or error message are different
                if (currentState.state !== prevState.state ||
                    currentState.error !== prevState.error ||
                    currentState.statusMessage !== prevState.statusMessage) {
                    statusLogger.info(`Simulation ${currentState.id} state changed to ${currentState.state} or status message was updated.`);
                    if (currentState.state === SimulationState.Error) {
                        events.push(actions.updateSimulationError(currentState.id, currentState.error));
                    }
                    else {
                        events.push(actions.updateSimulationState(currentState.id, currentState.state, currentState.statusMessage));
                    }
                }
                if (currentState.expires !== prevState.expires) {
                    events.push(actions.updateSimulationExpiry(currentState.id, currentState.expires));
                }
            }
            else if (!newSimsAlreadySeen.has(currentState.id)) {
                // New simulation
                const meta = yield call([virtualDeviceApi, virtualDeviceApi.getSimulationData], currentState.id, currentState.type === SimulationType.Public);
                statusLogger.info('New simulation created:', meta);
                events.push(actions.simulationCreated(meta));
                newSimsAlreadySeen.add(currentState.id);
            }
        }
        // Check for deleted simulations
        const currentSimIds = new Set(currentStates.map(s => s.id));
        for (const prevSimId in prevStates) {
            if (!currentSimIds.has(prevSimId)) {
                statusLogger.info(`Simulation ${prevSimId} deleted.`);
                events.push(actions.simulationRemoved(prevSimId));
                newSimsAlreadySeen.delete(prevSimId);
            }
        }
        // Dispatch events for anything that changed during this iteration.
        for (const action of events) {
            yield put(action);
        }
        yield delay(pollInterval !== null && pollInterval !== void 0 ? pollInterval : DEFAULT_POLL_INTERVAL);
    }
}
function* chainBuilderSelectDevice({ payload: { index, product } }) {
    try {
        const virtualDeviceApi = getContainer().get(VirtualDeviceApi);
        const api = getContainer().get(ZaberApi);
        const deviceId = yield call([virtualDeviceApi, virtualDeviceApi.getDeviceIdForProduct], product);
        yield put(actions.chainBuilderSetDeviceId(index, deviceId));
        const url = yield call(getProductImageUrl, deviceId, api);
        yield put(actions.chainBuilderSetImage(index, url));
    }
    catch (err) {
        throwIfJsError(err);
        statusLogger.error(err);
        yield put(actions.simulationCreationErr(getMessageFromError(err)));
    }
}
function* chainBuilderFinalize() {
    var _a;
    const virtualDeviceApi = getContainer().get(VirtualDeviceApi);
    const builder = yield select(selectChainBuilder);
    if (!((_a = builder.devices) === null || _a === void 0 ? void 0 : _a.length)) {
        return;
    }
    try {
        yield put(actions.chainBuilderSetFinalizing(true));
        const newSimulation = yield call([virtualDeviceApi, virtualDeviceApi.createDaisyChainSimulation], builder.name, builder.devices.filter(device => device.params.deviceId !== 0).map(device => device.params), SimulationType.Private);
        yield put(actions.creatingSimulation(newSimulation));
        yield put(actions.chainBuilderCancel());
    }
    catch (e) {
        throwIfJsError(e);
        if (getHttpCodeFromError(e) === 503) {
            yield put(actions.chainBuilderRetryFinalize());
        }
        yield put(actions.simulationCreationErr(getMessageFromError(e)));
    }
    finally {
        yield put(actions.chainBuilderSetFinalizing(false));
    }
}
function* retryCreate() {
    yield race({
        stop: take(ActionTypes.CANCEL_CREATE_RETRY),
        go: call(function* () {
            yield delay(RETRY_INTERVAL);
            const retry = (yield select(selectCreationRetryInfo));
            if (retry == null) {
                return;
            }
            else if (retry === 'chain') {
                yield put(actions.chainBuilderFinalize());
            }
            else {
                yield put(actions.createSimulation(retry.productName, retry.type));
            }
        }),
    });
}
function* retryTurnOn() {
    while (true) {
        yield delay(RETRY_INTERVAL);
        const retrying = (yield select(selectTurnOnRetryInfo));
        if (retrying.length === 0) {
            break;
        }
        yield put(actions.turnOnSimulation(retrying[0].id));
    }
}
