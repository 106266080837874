var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Icons, Text } from '@zaber/react-library';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { selectLoggedInUserEmail } from '../user';
import { actions as actionDefinitions } from './actions';
const DEFAULT_FEEDBACK_LINK_CONTENT = _jsxs("div", { className: "feedback-with-icon", children: [_jsx(Icons.Feedback, { appearsClickable: true }), "Leave Feedback"] });
export const FeedbackLink = (_a) => {
    var { children = DEFAULT_FEEDBACK_LINK_CONTENT, className, title = 'Open Feedback Form', whatFor = 'virtual-device' } = _a, rest = __rest(_a, ["children", "className", "title", "whatFor"]);
    const actions = bindActionCreators(actionDefinitions, useDispatch());
    const loggedInUserEmail = useSelector(selectLoggedInUserEmail);
    return (_jsx(Text, Object.assign({ className: className, onClick: () => {
            if (loggedInUserEmail) {
                actions.setEmail(loggedInUserEmail);
            }
            actions.open(whatFor);
        }, title: title }, rest, { children: children })));
};
