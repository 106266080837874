import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Input, Button, Text, Checkbox, LinkClasses, NoticeBanner } from '@zaber/react-library';
import { Link } from 'react-router-dom';
import { NavigationBar } from '../navigation_bar';
import { PageUrls } from '../urls';
import { useActions } from '../utils';
import { IconMessage } from '../components/IconMessage';
import { Footer } from '../components/Footer';
import { useJustMounted } from '../app_components';
import { PageTitle } from '../components/PageTitle';
import { selectLoginData, selectLoginError, selectLoginInProgress, selectResendSignUpEmail } from './selectors';
import { actions as actionDefinitions } from './actions';
import { EMAIL_NOT_CONFIRMED_ERROR } from './types';
export const ResendVerification = () => {
    const actions = useActions(actionDefinitions);
    const loginData = useSelector(selectLoginData);
    const { resendSignUpEmailInProgress, resendSignUpEmailError } = useSelector(selectResendSignUpEmail);
    const [resendWasTriggered, setResendWasTriggered] = useState(false);
    const justMounted = useJustMounted();
    if (resendSignUpEmailInProgress) {
        return (_jsx(IconMessage, { className: "resend-email", icon: "Gear", rotatingIcon: true, children: "Sending new email with verification details" }));
    }
    if (resendSignUpEmailError) {
        return (_jsxs(IconMessage, { className: "resend-email error", icon: "ErrorFault", children: [_jsxs(Text, { t: Text.Type.H4, children: ["We encountered an error while sending a verification email to ", loginData.email] }), _jsx(Text, { children: resendSignUpEmailError }), _jsx(Button, { color: "grey", disabled: resendSignUpEmailInProgress, onClick: () => actions.resendSignUpEmail(loginData.email), children: "Try Again" })] }));
    }
    if (!justMounted && resendWasTriggered) {
        return (_jsx(IconMessage, { className: "resend-email", icon: "Tick", color: "green", children: _jsxs(Text, { e: Text.Emphasis.Bold, children: ["Another verification email has been sent to ", loginData.email] }) }));
    }
    return (_jsxs(IconMessage, { className: "resend-email info", icon: "ErrorNote", children: [_jsx(Text, { t: Text.Type.H4, children: "Your account is not verified." }), _jsx(Text, { children: "If you did not recieve a verification email after creating your account then you can request a new email below" }), _jsx(Button, { color: "grey", disabled: resendSignUpEmailInProgress, onClick: () => {
                    setResendWasTriggered(true);
                    actions.resendSignUpEmail(loginData.email);
                }, children: "Resend Verification Email" })] }));
};
export const Login = () => {
    const loginData = useSelector(selectLoginData);
    const inProgress = useSelector(selectLoginInProgress);
    const error = useSelector(selectLoginError);
    const actions = useActions(actionDefinitions);
    const [rememberMe, setRememberMe] = useState(false);
    useEffect(() => () => {
        actions.updateLoginData({ password: '' });
        actions.resetErrors();
    }, []);
    const emailNotConfirmed = error === EMAIL_NOT_CONFIRMED_ERROR;
    return (_jsxs("div", { className: "login page", children: [_jsx(PageTitle, { children: "Login" }), _jsx(NavigationBar, {}), _jsxs("div", { className: "content", children: [_jsx(Text, { t: Text.Type.H1, e: Text.Emphasis.Light, children: "Sign in to Account" }), _jsxs("div", { className: "input-container", children: [_jsx(Input, { type: "text", name: "email", labelContent: "Email Address", disabled: inProgress, value: loginData.email, onChange: e => actions.updateLoginData({ email: e.target.value }) }), _jsx(Input, { type: "password", name: "password", labelContent: "Password", disabled: inProgress, value: loginData.password, onKeyDown: e => e.key === 'Enter' && !inProgress && actions.login(), onChange: e => actions.updateLoginData({ password: e.target.value }) }), error && !emailNotConfirmed && _jsx(NoticeBanner, { type: "error", children: error }), error && emailNotConfirmed && _jsx(ResendVerification, {}), _jsxs("div", { className: "login-options", children: [_jsx(Checkbox, { labelContent: "Remember me", checked: rememberMe, onChecked: setRememberMe }), _jsx(Link, { to: PageUrls.PasswordReset, className: LinkClasses.Default, children: _jsx(Text, { children: "Forgot Password?" }) })] })] }), _jsx(Button, { className: "sign-in-button", disabled: inProgress, onClick: actions.login, children: "Sign In" }), _jsx(Link, { to: PageUrls.Signup, className: LinkClasses.Default, children: _jsx(Text, { children: "Create Account" }) })] }), _jsx(Footer, {})] }));
};
