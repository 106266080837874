export var PageUrls;
(function (PageUrls) {
    PageUrls["Auth"] = "/auth";
    PageUrls["AuthAccessTokens"] = "/auth/access-tokens";
    PageUrls["AuthAccount"] = "/auth/account";
    PageUrls["AuthDeleteAccount"] = "/auth/delete-account";
    PageUrls["AuthPayments"] = "/auth/payments";
    PageUrls["CodeExamples"] = "/examples";
    PageUrls["Demo"] = "/demo";
    PageUrls["Login"] = "/login";
    PageUrls["PasswordReset"] = "/password-reset";
    PageUrls["PrivacyPolicy"] = "/privacy-policy";
    PageUrls["Signup"] = "/signup";
    PageUrls["TermsAndConditions"] = "/tos";
    PageUrls["VirtualDeviceHome"] = "/virtual-device/home";
    PageUrls["VirtualDeviceManager"] = "/virtual-device/manager";
    PageUrls["VirtualDeviceReleaseNotes"] = "/virtual-device/release-notes";
    PageUrls["VirtualDeviceViewer"] = "/virtual-device/viewer";
    PageUrls["ZaberLauncher"] = "/zaber-launcher";
    PageUrls["ZaberLauncherTutorials"] = "/zaber-launcher/tutorials";
    PageUrls["ZaberLauncherDownloadPage"] = "/zaber-launcher/download";
    PageUrls["ZaberLauncherOfflinePage"] = "/zaber-launcher/offline";
    PageUrls["Zml"] = "/motion-library";
    PageUrls["ZmlHome"] = "/motion-library/docs";
    PageUrls["ZmlVirtualDeviceHowTo"] = "/motion-library/docs/guides/virtual_device";
})(PageUrls || (PageUrls = {}));
/** Pages that are suppose to be indexed by bots. */
export const SITEMAP = [
    PageUrls.VirtualDeviceHome,
    PageUrls.ZaberLauncherDownloadPage,
    PageUrls.ZaberLauncherTutorials,
    PageUrls.ZaberLauncherOfflinePage,
    PageUrls.Login,
    PageUrls.Signup,
];
export function createSimulationViewerSimulationIdUrl(simulationId) {
    return `${PageUrls.VirtualDeviceViewer}?simulationid=${simulationId}`;
}
export function createSimulationViewerProductUrl(productName) {
    return `${PageUrls.VirtualDeviceViewer}?product=${productName}`;
}
