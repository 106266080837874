import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { HeaderCard, Icons, NoticeBanner, Text } from '@zaber/react-library';
import { useActions } from '../utils';
import { actions as actionsDefinition } from './actions';
export const Axis = ({ axis }) => {
    const actions = useActions(actionsDefinition);
    const moveLeft = () => actions.move(axis.id, 'left');
    const moveRight = () => actions.move(axis.id, 'right');
    const stop = () => actions.move(axis.id, 'stop');
    return (_jsxs(HeaderCard, { className: "axis", header: _jsxs(Text, { t: Text.Type.H4, children: [axis.deviceName, axis.name && _jsxs(_Fragment, { children: [" \u2192 ", axis.name] })] }), children: [axis.error && _jsx(NoticeBanner, { children: axis.error }), _jsx(Icons.LeftNormal, { title: "Move Left", appearsClickable: true, onMouseDown: moveLeft, onTouchStart: moveLeft, onTouchEnd: stop, onMouseUp: stop }), _jsx(Icons.RightNormal, { title: "Move Right", appearsClickable: true, onMouseDown: moveRight, onTouchStart: moveRight, onTouchEnd: stop, onMouseUp: stop }), _jsx("div", { className: "spacer" }), _jsxs("div", { className: "position", children: [axis.position.toFixed(2), " ", axis.type === 'linear' ? 'mm' : 'deg'] })] }));
};
