import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { NavigationBar } from '../navigation_bar';
import { selectCreationRetryInfo, selectPrivateSimulations, selectPrivateSimulationsLoading, selectSimulationCreationError, selectTurnOnRetryInfo } from '../virtual_device/selectors';
import { useActions } from '../utils';
import { selectAuthState } from '../user/selectors';
import { AuthState } from '../user';
import { actions as terminalActionDefinitions } from '../terminal';
import { actions as virtualDeviceActionDefinitions } from '../virtual_device/actions';
import { SimulationState } from '../virtual_device_api';
import { PageUrls } from '../urls';
import { PageTitle } from '../components/PageTitle';
import { useLoadPrivateSimulations, useLoadSimulationProducts } from '../virtual_device/effects';
import { actions as actionDefinitions } from './actions';
import { SimulationSelectionBar } from './SimulationSelectionBar';
import { ViewerMessageOverlay } from './ViewerMessageOverlay';
import { ViewerControlsOverlay } from './ViewerControlsOverlay';
import { Viewer3DScene } from './Viewer3DScene';
import { selectCurrentViewMode, selectSimulationCheckError, selectCurrentSimulation } from './selectors';
import { ViewModes } from './types';
import { ViewerSimulationError } from './ViewerSimulationError';
const NoDevices = _jsxs(ViewerMessageOverlay, { title: "You currently have no virtual devices", children: ["Please add some in the ", _jsx(Link, { to: PageUrls.VirtualDeviceManager, children: "My Virtual Devices" }), " page"] });
const InnerContent = () => {
    var _a;
    const authState = useSelector(selectAuthState);
    const privateSimulations = useSelector(selectPrivateSimulations);
    const simulationCreationError = useSelector(selectSimulationCreationError);
    const simulationCheckError = useSelector(selectSimulationCheckError);
    const viewMode = useSelector(selectCurrentViewMode);
    const privateSimulationsLoading = useSelector(selectPrivateSimulationsLoading);
    const currentSimulation = useSelector(selectCurrentSimulation);
    const isRetryingTurnOn = useSelector(selectTurnOnRetryInfo).map(info => info.id).includes((_a = currentSimulation === null || currentSimulation === void 0 ? void 0 : currentSimulation.id) !== null && _a !== void 0 ? _a : '');
    const createRetry = useSelector(selectCreationRetryInfo);
    const isRetryingCreation = (createRetry && createRetry !== 'chain');
    const [cameraResetToken, setCameraResetToken] = useState(0);
    const resetCamera = () => setCameraResetToken(cameraResetToken + 1);
    const overlays = () => {
        switch (viewMode) {
            case ViewModes.NoSimulationSelected:
                if (authState === AuthState.Authenticated && (privateSimulationsLoading === null || privateSimulationsLoading === void 0 ? void 0 : privateSimulationsLoading.state) === 'loading') {
                    return _jsx(ViewerMessageOverlay, { title: "Loading device information", children: "Please wait..." });
                }
                if (authState === AuthState.Authenticated && !(privateSimulations === null || privateSimulations === void 0 ? void 0 : privateSimulations.length)) {
                    return NoDevices;
                }
                return _jsx(ViewerMessageOverlay, { children: "Please select a Virtual Device to start" });
            case ViewModes.Simulation:
                return _jsx(ViewerControlsOverlay, { onCameraReset: resetCamera });
            case ViewModes.SimulationError:
                return isRetryingTurnOn ? _jsx(ViewerControlsOverlay, { onCameraReset: resetCamera })
                    : _jsx(ViewerSimulationError, {});
            case ViewModes.SimulationRemoved:
                if (authState === AuthState.Authenticated && !(privateSimulations === null || privateSimulations === void 0 ? void 0 : privateSimulations.length)) {
                    return NoDevices;
                }
                return _jsx(ViewerMessageOverlay, { title: "Virtual Device removed", children: "Please select a device from the dropdown above" });
            case ViewModes.CreatingSimulation:
                return _jsx(ViewerMessageOverlay, { type: "spinner", title: isRetryingCreation ? 'Checking system status' : 'Creating Virtual Device ...' });
            case ViewModes.CreationError:
                if (isRetryingCreation) {
                    return _jsx(ViewerMessageOverlay, { type: "spinner", title: "Expect Delays", children: "Due to high system load, creating your Virtual Device is taking longer than expected. Leave this window open to continue waiting." });
                }
                else {
                    return _jsxs(ViewerMessageOverlay, { type: "error", title: "Error occurred while creating Virtual Device", children: [simulationCreationError, _jsx("br", {}), _jsx("br", {}), "Please select a simulation from the dropdown above."] });
                }
            case ViewModes.CheckingSimulation:
                return _jsx(ViewerMessageOverlay, { type: "spinner", title: "Checking Virtual Device ..." });
            case ViewModes.CheckError:
                return _jsx(ViewerMessageOverlay, { type: "error", title: "Error occurred while checking Virtual Device", children: simulationCheckError });
        }
    };
    return _jsxs(_Fragment, { children: [_jsx(Viewer3DScene, { cameraResetToken: cameraResetToken }), overlays()] });
};
export const VirtualDeviceViewer = () => {
    var _a;
    const actions = useActions(actionDefinitions);
    const terminalActions = useActions(terminalActionDefinitions);
    const deviceActions = useActions(virtualDeviceActionDefinitions);
    const currentSimulation = useSelector(selectCurrentSimulation);
    useLoadSimulationProducts();
    useLoadPrivateSimulations();
    useEffect(() => {
        deviceActions.startMonitoring();
        return () => {
            deviceActions.cancelCreateRetry();
            actions.resetState();
            actions.stopMonitoringSimulationPos();
            terminalActions.stopMonitoring();
            deviceActions.stopMonitoring();
        };
    }, []);
    useEffect(() => {
        if ((currentSimulation === null || currentSimulation === void 0 ? void 0 : currentSimulation.state) && [SimulationState.On, SimulationState.TurningOn].includes(currentSimulation.state)) {
            terminalActions.monitorConnection(currentSimulation.id);
        }
        else {
            terminalActions.stopMonitoring();
        }
    }, [currentSimulation === null || currentSimulation === void 0 ? void 0 : currentSimulation.state, currentSimulation === null || currentSimulation === void 0 ? void 0 : currentSimulation.id]);
    return (_jsxs("div", { className: "virtual-device-viewer", children: [_jsx(PageTitle, { children: `Virtual Device ${(_a = currentSimulation === null || currentSimulation === void 0 ? void 0 : currentSimulation.name) !== null && _a !== void 0 ? _a : ''}` }), _jsx(NavigationBar, {}), _jsx(SimulationSelectionBar, {}), _jsx("div", { className: "viewer-container", children: _jsx(InnerContent, {}) })] }));
};
