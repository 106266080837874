import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Text, Icons } from '@zaber/react-library';
import classNames from 'classnames';
import { useMemo } from 'react';
export function useValidation(requirements, ...args) {
    const validations = useMemo(() => requirements.map(requirement => ({
        text: requirement.text,
        isValid: requirement.validator(...args)
    })), args);
    const allAreValid = useMemo(() => validations.reduce((valueSoFar, validation) => valueSoFar && validation.isValid, true), [validations]);
    return [validations, allAreValid];
}
export const InputValidation = ({ validations, highlightInvalidValidations, className }) => (_jsx("div", { className: classNames(className, 'input-validation'), children: validations.map(validation => _jsxs("div", { className: classNames(validation.isValid ? 'valid' : (highlightInvalidValidations ? 'invalid' : ''), 'validation'), children: [_jsx(Icons.Tick, {}), _jsx(Text, { t: Text.Type.Helper, children: validation.text })] }, validation.text)) }));
