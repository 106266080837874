import { actionBuilder } from '../utils';
export var ActionTypes;
(function (ActionTypes) {
    ActionTypes["SET_FEEDBACK_MODAL_STATE"] = "FEEDBACK_SET_MODAL_STATE";
    ActionTypes["SEND_FEEDBACK"] = "FEEDBACK_SEND";
    ActionTypes["SEND_FEEDBACK_DONE"] = "SEND_FEEDBACK_DONE";
    ActionTypes["SET_FEEDBACK_TEXT"] = "FEEDBACK_SET_TEXT";
    ActionTypes["SET_FEEDBACK_ANONYMOUS"] = "FEEDBACK_SET_ANONYMOUS";
    ActionTypes["SET_FEEDBACK_EMAIL"] = "FEEDBACK_SET_REPLY_EMAIL";
})(ActionTypes || (ActionTypes = {}));
const buildAction = (type, value) => actionBuilder(type, value);
export const actions = {
    open: (whatFor = 'virtual-device') => buildAction(ActionTypes.SET_FEEDBACK_MODAL_STATE, { feedbackModalState: 'gather', whatFor }),
    close: () => buildAction(ActionTypes.SET_FEEDBACK_MODAL_STATE, { feedbackModalState: 'closed' }),
    sendFeedback: () => buildAction(ActionTypes.SEND_FEEDBACK),
    sendFeedbackDone: (error) => buildAction(ActionTypes.SEND_FEEDBACK_DONE, { error }),
    setText: (text) => buildAction(ActionTypes.SET_FEEDBACK_TEXT, { text }),
    setAnonymous: (anonymous) => buildAction(ActionTypes.SET_FEEDBACK_ANONYMOUS, { anonymous }),
    setEmail: (email) => buildAction(ActionTypes.SET_FEEDBACK_EMAIL, { email }),
};
