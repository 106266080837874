import { jsx as _jsx } from "react/jsx-runtime";
import { Colors, Text } from '@zaber/react-library';
import { SameTabLink } from '../components/Links';
import { VideoPlayer } from './VideoPlayer';
import { getTutorialMetadata, getTutorialUrl, getVideoPosterUrl, getVideoUrl } from './content';
import { TUTORIALS } from './markdown/content_generated';
import { LinkWithImage } from './LinkWithImage';
import { ZL_APP_ICONS } from './icons';
let eId = 1;
function nextEid() {
    return `z-${eId++}`;
}
function replaceVideo(html) {
    // eslint-disable-next-line @typescript-eslint/no-base-to-string
    if (html === null || html === void 0 ? void 0 : html.toString().startsWith('<video')) {
        let videoUrl = null;
        let withCaptions = true;
        let withChapters = true;
        let isTutorial = false;
        // eslint-disable-next-line @typescript-eslint/no-base-to-string
        for (const part of html.toString().replace('>', '').split(' ')) {
            if (part === 'nocaptions') {
                withCaptions = false;
            }
            else if (part === 'nochapters') {
                withChapters = false;
            }
            else {
                const match = part.match(/src="([^"]*)"/);
                if (match) {
                    videoUrl = match[1];
                    if (Object.keys(TUTORIALS).includes(videoUrl)) {
                        isTutorial = true;
                        videoUrl = getVideoUrl(videoUrl);
                    }
                }
            }
        }
        if (!videoUrl) {
            return _jsx(Text, { e: Text.Emphasis.Bold, color: Colors.justRed, children: "Broken video link!" });
        }
        const captionsUrl = isTutorial && withCaptions ? videoUrl.replace(/\.mpd$/, '.srt') : null;
        const chaptersUrl = isTutorial && withChapters ? videoUrl.replace(/\.mpd$/, '.vtt') : null;
        return _jsx(VideoPlayer, { videoUrl: videoUrl, captionsUrl: captionsUrl, chaptersUrl: chaptersUrl }, nextEid());
    }
    else if (html === '</video>') {
        return null;
    }
    return html;
}
function replaceLink(href, text) {
    if (Object.keys(TUTORIALS).includes(href)) {
        const meta = getTutorialMetadata(href);
        return _jsx(LinkWithImage, { to: getTutorialUrl(meta.key), imageUrl: getVideoPosterUrl(meta.poster), label: meta.title }, nextEid());
    }
    return _jsx(SameTabLink, { to: href, children: text }, nextEid());
}
function replaceImage(src, alt, title) {
    let className = '';
    if (Object.keys(ZL_APP_ICONS).includes(src)) {
        src = ZL_APP_ICONS[src];
        className = 'zl-app-icon';
    }
    return _jsx("img", { src: src, alt: alt, title: title !== null && title !== void 0 ? title : undefined, className: className }, nextEid());
}
export const CustomRenderer = {
    html(html) {
        return replaceVideo(html);
    },
    heading(children, level) {
        // The design calls for top-level markdown headings to be H3 from our react library.
        const headingType = [Text.Type.H1, Text.Type.H2, Text.Type.H3, Text.Type.H4, Text.Type.H5][Math.min(level + 2, 5) - 1];
        return _jsx(Text, { t: headingType, children: children }, nextEid());
    },
    link(href, text) {
        return replaceLink(href, text);
    },
    paragraph(children) {
        return _jsx("div", { className: "paragraph", children: children }, nextEid());
    },
    image(src, alt, title) {
        return replaceImage(src, alt, title);
    }
};
