import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Text, LinkClasses, Icons } from '@zaber/react-library';
import { useMemo } from 'react';
import { ExternalPageUrls } from '../urls';
import { FeedbackLink } from '../feedback/FeedbackLink';
import { SameTabLink } from '../components/Links';
export const TutorialFooter = () => {
    const currentYear = useMemo(() => new Date().getFullYear(), []);
    return (_jsxs("div", { className: "tutorial-footer", children: [_jsx("div", { className: "options-spacer", children: _jsxs("div", { className: "options", children: [_jsxs("div", { className: "column", children: [_jsx(Text, { e: Text.Emphasis.Bold, children: "Help Us Improve Tutorials" }), _jsx(FeedbackLink, { className: LinkClasses.Default, whatFor: "tutorials" })] }), _jsx("div", { className: "separator" }), _jsxs("div", { className: "column", children: [_jsx(Text, { e: Text.Emphasis.Bold, children: "Have A Question?" }), _jsx(SameTabLink, { to: ExternalPageUrls.ZaberContact, className: LinkClasses.Default, children: _jsxs("div", { className: "contact-with-icon", children: [_jsx(Icons.Support, { appearsClickable: true }), _jsx(Text, { children: "Contact Support" })] }) })] })] }) }), _jsx("div", { className: "copyright", children: _jsxs(Text, { children: ["\u00A9 ", currentYear, " Zaber Technologies Inc."] }) })] }));
};
