import { HttpError } from '@zaber/app-components/lib/request';
import { ApiError } from '@zaber/app-components/lib/zaber_api/error';
import { makeString } from '@zaber/toolbox';
import { useEffect, useRef } from 'react';
export * from '@zaber/app-components/lib/';
export * from '@zaber/app-components/lib/iot';
export * from '@zaber/app-components/lib/aws';
export * from '@zaber/app-components/lib/zaber_api';
export * from '@zaber/app-components/lib/log';
export * from '@zaber/app-components/lib/units';
export * from '@zaber/app-components/lib/message_router/rpc/rpc_io_iot';
export * from '@zaber/app-components/lib/message_router/rpc/rpc_client';
export * from '@zaber/app-components/lib/message_router/rpc/rpc_notifications';
export * from '@zaber/app-components/lib/message_router/routed_connection';
export * from '@zaber/app-components/lib/message_router/monitor_connection';
export * from '@zaber/app-components/lib/message_router/router_api';
export * from '@zaber/app-components/lib/message_router/types';
/**
 * This hook will return `true` on first render of a component and will return `false`
 * for all other re-renders
 * @returns `true` if the component has just mounted. `false` otherwise
 */
export function useJustMounted() {
    const ref = useRef(true);
    useEffect(() => {
        ref.current = false;
    }, []);
    return ref.current;
}
export function useWindowEvent(type, onEvent) {
    useEffect(() => {
        window.addEventListener(type, onEvent);
        return () => window.removeEventListener(type, onEvent);
    }, [type, onEvent]);
}
export function getMessageFromError(error) {
    if (error instanceof Error) {
        if (error instanceof ApiError && error.innerError instanceof HttpError && error.innerError.result) {
            return makeString(error.innerError.result);
        }
        return error.message || String(error);
    }
    return makeString(error);
}
export function getHttpCodeFromError(error) {
    if (error instanceof ApiError && error.innerError instanceof HttpError && error.innerError.status) {
        return error.innerError.status;
    }
    return undefined;
}
