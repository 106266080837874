import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { AnimationClasses, Button, Icons, Input, Text, LinkClasses } from '@zaber/react-library';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { useActions } from '@zaber/toolbox/lib/redux';
import { NavigationBar } from '../navigation_bar';
import { PageUrls } from '../urls';
import { IconMessage } from '../components/IconMessage';
import { Footer } from '../components/Footer';
import { environment } from '../environment';
import { InputValidation, useValidation } from '../components/InputValidation';
import { PageTitle } from '../components/PageTitle';
import { PASSWORD_AGAIN_VALIDATION_REQUIREMENTS, PRODUCTION_ENV_PASSWORD_VALIDATION_REQUIREMENTS, NON_PRODUCTION_ENV_PASSWORD_VALIDATION_REQUIREMENTS } from './types';
import { actions as actionDefinitions } from './actions';
import { selectLoginData, selectPasswordReset } from './selectors';
const passwordValidationRequirements = environment.build === 'production'
    ? PRODUCTION_ENV_PASSWORD_VALIDATION_REQUIREMENTS
    : NON_PRODUCTION_ENV_PASSWORD_VALIDATION_REQUIREMENTS;
const PageContent = () => {
    const actions = useActions(actionDefinitions);
    const loginData = useSelector(selectLoginData);
    const { passwordResetRequestInProgress, passwordResetSubmitInProgress, passwordResetError } = useSelector(selectPasswordReset);
    const [requestSent, setRequestSent] = useState(false);
    const [newPasswordSubmitted, setNewPasswordSubmitted] = useState(false);
    const [resetCode, setResetCode] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [newPasswordAgain, setNewPasswordAgain] = useState('');
    const [passwordValidations, passwordIsValid] = useValidation(passwordValidationRequirements, newPassword);
    const [passwordAgainValidations, passwordAgainIsValid] = useValidation(PASSWORD_AGAIN_VALIDATION_REQUIREMENTS, newPassword, newPasswordAgain);
    const [highlightInvalidValidations, setHighlightInvalidValidations] = useState(false);
    useEffect(() => {
        setResetCode('');
        setNewPassword('');
        setNewPasswordAgain('');
    }, [passwordResetError]);
    if (passwordResetRequestInProgress || passwordResetSubmitInProgress) {
        return _jsx(Icons.Gear, { className: classNames(AnimationClasses.Rotation, 'in-progress') });
    }
    if (!requestSent) {
        return (_jsxs(_Fragment, { children: [_jsx(Text, { className: "info", children: "Enter your account email address below and we will send you information on how to reset your password." }), _jsx("div", { className: "input-container", children: _jsx(Input, { type: "text", name: "email", labelContent: "Email Address", value: loginData.email, onChange: e => actions.updateLoginData({ email: e.target.value }) }) }), _jsx(Button, { disabled: !loginData.email, onClick: () => {
                        actions.requestPasswordReset(loginData.email);
                        setRequestSent(true);
                    }, children: "Request Password Reset" })] }));
    }
    else {
        if (passwordResetError && !newPasswordSubmitted) {
            return _jsx(IconMessage, { icon: "ErrorWarning", color: "red", className: "error", children: passwordResetError });
        }
        return (_jsxs(_Fragment, { children: [_jsxs(Text, { className: "info centered", children: ["An email will be sent to ", _jsx("b", { children: loginData.email }), " shortly if there is an existing account with that address."] }), _jsx(Text, { className: "info centered", children: "Please enter the code from the email below to reset your password." }), _jsx(Text, { className: "info centered", children: "If you do not receive the email within fifteen minutes, please verify that you are using the right email address and try again." }), _jsxs("div", { className: "input-container", children: [_jsx(Input, { type: "text", name: "reset-code", labelContent: "Code from the email", value: resetCode, onChange: e => setResetCode(e.target.value) }), _jsx(Input, { type: "password", name: "new-password", labelContent: "New password", value: newPassword, status: highlightInvalidValidations && !passwordIsValid ? 'error' : null, onChange: e => setNewPassword(e.target.value) }), _jsx(InputValidation, { validations: passwordValidations, highlightInvalidValidations: highlightInvalidValidations }), _jsx(Input, { type: "password", name: "new-password-again", labelContent: "Confirm new password", value: newPasswordAgain, status: highlightInvalidValidations && !passwordAgainIsValid ? 'error' : null, onChange: e => setNewPasswordAgain(e.target.value) }), _jsx(InputValidation, { validations: passwordAgainValidations, highlightInvalidValidations: highlightInvalidValidations }), passwordResetError && newPasswordSubmitted && _jsx(IconMessage, { icon: "ErrorWarning", color: "red", className: "error", children: passwordResetError })] }), _jsx(Button, { disabled: !loginData.email || !resetCode || !newPassword || !newPasswordAgain, onClick: () => {
                        setHighlightInvalidValidations(!passwordIsValid || !passwordAgainIsValid);
                        if (passwordIsValid && passwordAgainIsValid) {
                            actions.submitPasswordReset(loginData.email, resetCode, newPassword);
                            setNewPasswordSubmitted(true);
                        }
                    }, children: "Reset Password" })] }));
    }
};
export const PasswordReset = () => {
    const actions = useActions(actionDefinitions);
    useEffect(() => () => {
        actions.passwordResetClear();
        actions.resetErrors();
    }, []);
    return (_jsxs("div", { className: "password-reset page", children: [_jsx(PageTitle, { children: "Password Reset" }), _jsx(NavigationBar, {}), _jsxs("div", { className: "content", children: [_jsx(Text, { t: Text.Type.H1, e: Text.Emphasis.Light, children: "Forgot your password?" }), _jsx(PageContent, {}), _jsx(Link, { to: PageUrls.Login, className: LinkClasses.Default, children: _jsx(Text, { children: "Back to Sign In" }) })] }), _jsx(Footer, {})] }));
};
