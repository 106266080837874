import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Text, Button, Icons, AnimationClasses } from '@zaber/react-library';
import { useSelector } from 'react-redux';
import { useActions } from '../utils';
import { IconMessage } from '../components/IconMessage';
import { AccessTokenList } from './AccessTokenList';
import { CreateToken } from './CreateToken';
import { actions as actionDefinitions } from './actions';
import { selectAccessTokenCreateWanted, selectAccessTokensLoading, selectAccessTokensLoadError, selectAccessTokens } from './selectors';
import { TokensLoadErrorPopup } from './TokensLoadErrorPopup';
const AccessTokenInfo = () => (_jsxs(Text, { children: ["Access tokens are a secure way to access Zaber software services in your applications or scripts without having to directly provide a user name and password. Access tokens are long strings that are unique to your account only. You can create as many access tokens as you need.", _jsx("p", { children: "An access token is required for interacting with Virtual Devices or Cloud Shares using the Zaber Motion Library." })] }));
export const AccessTokens = () => {
    const actions = useActions(actionDefinitions);
    const userWantsToCreateAccessToken = useSelector(selectAccessTokenCreateWanted);
    const tokens = useSelector(selectAccessTokens);
    const tokensLoading = useSelector(selectAccessTokensLoading);
    const tokensLoadError = useSelector(selectAccessTokensLoadError);
    return (_jsxs("div", { className: "card-content", children: [_jsx(AccessTokenInfo, {}), userWantsToCreateAccessToken
                ? _jsx(CreateToken, {})
                : (tokens != null && !tokens.filter(t => !t.createdBy).length
                    ? _jsxs("div", { className: "no-tokens-found", children: [_jsx(IconMessage, { icon: "ErrorNote", children: "You don't have any active tokens. Start by creating one" }), _jsx(Button, { color: "red", onClick: () => actions.updateCreateAccessTokenWanted(true), children: "Create Access Token" })] })
                    : _jsx(Button, { color: "grey", onClick: () => actions.updateCreateAccessTokenWanted(true), children: "Create Access Token" })), _jsxs("div", { className: "token-list-container", children: [_jsx(AccessTokenList, {}), tokensLoadError && _jsx(TokensLoadErrorPopup, { tokensLoadError: tokensLoadError }), tokensLoading && _jsxs("div", { className: "tokens-loading", children: [_jsx(Icons.Gear, { className: AnimationClasses.Rotation }), _jsx(Text, { children: "Loading tokens..." })] })] })] }));
};
