import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Toggle, Text, Icons, PopUp } from '@zaber/react-library';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { SimulationState } from '../virtual_device_api';
import { useActions } from '../utils';
import { IconMessage } from '../components/IconMessage';
import { actions as actionDefinitions } from './actions';
import { selectTurnOnRetryInfo } from './selectors';
const statusText = {
    [SimulationState.Creating]: 'Creating',
    [SimulationState.On]: 'Running',
    [SimulationState.TurningOn]: 'Turning on',
    [SimulationState.Off]: 'Off',
    [SimulationState.TurningOff]: 'Turning off',
    [SimulationState.Deleting]: 'Deleting',
    [SimulationState.Deleted]: 'Deleted',
    [SimulationState.Error]: 'Error',
};
const statesConsideredOn = [SimulationState.On, SimulationState.TurningOn, SimulationState.Error];
const statesConsideredInProgress = [
    SimulationState.Creating, SimulationState.TurningOn, SimulationState.TurningOff, SimulationState.Deleting
];
const toggleElementTitle = 'Simulation on/off Toggle';
export const VirtualDeviceOnOffToggle = ({ simulation, disabled }) => {
    var _a, _b;
    const actions = useActions(actionDefinitions);
    const isRetryingTurnOn = useSelector(selectTurnOnRetryInfo).map(info => info.id).includes((_a = simulation === null || simulation === void 0 ? void 0 : simulation.id) !== null && _a !== void 0 ? _a : '');
    if (!simulation) {
        return (_jsx("div", { className: "simulation-on-off-toggle", children: _jsx(Toggle, { className: "external on-off-toggle", disabled: true, title: toggleElementTitle }) }));
    }
    const id = simulation.id;
    const simulationIsOn = statesConsideredOn.includes(simulation.state);
    const inProgress = statesConsideredInProgress.includes(simulation.state);
    const simulationHasError = simulation.state === SimulationState.Error || simulation.error != null;
    const toggle = (_jsx(Toggle, { value: simulationIsOn || isRetryingTurnOn, loading: inProgress || isRetryingTurnOn, className: "external on-off-toggle", onClick: () => {
            if (isRetryingTurnOn) {
                actions.clearSimulationError(id);
                actions.cancelRetryTurnOn(id);
            }
        }, onValueChange: () => {
            if (!isRetryingTurnOn) {
                if (simulationIsOn) {
                    actions.turnOffSimulation(id);
                }
                else {
                    actions.turnOnSimulation(id);
                }
            }
        }, disabled: disabled, title: toggleElementTitle }));
    return (_jsxs("div", { className: "simulation-on-off-toggle", children: [simulationHasError && _jsxs(PopUp, { className: "error-popup", triggerAction: "hover", trigger: () => (_jsxs("div", { className: "error-toggle", children: [!isRetryingTurnOn && _jsx(Icons.Dot, { className: "error-dot" }), isRetryingTurnOn && _jsx(Icons.ErrorWarning, { className: "info-dot" }), toggle] })), children: [!isRetryingTurnOn && _jsxs(IconMessage, { icon: "ErrorFault", color: "red", colorIconOnly: true, children: [_jsx(Text, { t: Text.Type.H5, children: "An error has occurred:" }), _jsx(Text, { children: (_b = simulation.error) !== null && _b !== void 0 ? _b : '' }), _jsx(Text, { className: "refresh-message", children: "Please refresh the page" })] }), isRetryingTurnOn && _jsxs(IconMessage, { icon: "ErrorWarning", color: "orange", colorIconOnly: true, children: [_jsx(Text, { t: Text.Type.H5, children: "High system load is delaying the startup of this device." }), _jsx(Text, { className: "refresh-message", children: "It will be retried automatically, or you can click the switch again to cancel." })] })] }), !simulationHasError && toggle, _jsxs(Text, { className: classNames('status', { disabled, green: !disabled && simulation.state === SimulationState.On }), children: [isRetryingTurnOn ? 'Retrying' : simulation.statusMessage || statusText[simulation.state] || '', SimulationState.Creating === simulation.state && _jsx(Text, { t: Text.Type.Instruction, children: "\u00A0(takes 1-2 minutes)" })] })] }));
};
