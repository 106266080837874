import { Units, Length, Angle, ascii } from '@zaber/motion';
import _ from 'lodash';
import { dimensions } from '../app_components';
export const dimensionsByName = _.keyBy(dimensions, 'DimensionName');
export const unitsById = _.keyBy(_.flatMap(dimensions, dimension => dimension.units), 'id');
const UNITS_DIMENSION_DELIMITER = ':';
const DEFAULT_DIMENSION_FOR_AXIS_TYPE = {
    [ascii.AxisType.LINEAR]: getDimensionName(Length.mm),
    [ascii.AxisType.ROTARY]: getDimensionName(Angle.DEGREES),
};
// default units overrides
dimensionsByName[getDimensionName(Length.mm)].defaultUnit = Length.mm;
function numberIsFinite(n) {
    return Number.isFinite(n);
}
export function getDimensionName(units) {
    var _a;
    return (_a = units.split(UNITS_DIMENSION_DELIMITER, 1)[0]) !== null && _a !== void 0 ? _a : '';
}
export const getUnitLabel = (unit) => {
    if (unit === Units.NATIVE) {
        return 'Native';
    }
    const unitData = unitsById[unit];
    return unitData === null || unitData === void 0 ? void 0 : unitData.ShortName;
};
export function getDefaultDimension(axisType) {
    var _a;
    return (_a = DEFAULT_DIMENSION_FOR_AXIS_TYPE[axisType]) !== null && _a !== void 0 ? _a : null;
}
export function getDefaultDimensionUnits(dimension) {
    const dimensionDef = dimensionsByName[dimension];
    if (!dimensionDef) {
        throw new Error(`Unknown dimension: ${dimension}`);
    }
    return dimensionDef.defaultUnit;
}
export function convertFromDefaultUnits(value, convertTo) {
    if (convertTo === Units.NATIVE) {
        throw new Error('Cannot convert to native units');
    }
    if (!numberIsFinite(value)) {
        return value;
    }
    const dimension = dimensionsByName[getDimensionName(convertTo)];
    if (!dimension) {
        throw new Error(`Unknown units: ${convertTo}`);
    }
    const defaultUnit = dimension.units.find(unit => unit.id === dimension.defaultUnit);
    const targetUnit = dimension.units.find(unit => unit.id === convertTo);
    if (!defaultUnit || !targetUnit) {
        return null;
    }
    if (convertTo === defaultUnit.id) {
        return value;
    }
    return convertBetweenUnits(value, defaultUnit, targetUnit);
}
export function convertToDefaultUnits(value, convertFrom) {
    if (convertFrom === Units.NATIVE) {
        throw new Error('Cannot convert from native units');
    }
    if (!numberIsFinite(value)) {
        return value;
    }
    const dimension = dimensionsByName[getDimensionName(convertFrom)];
    if (!dimension) {
        throw new Error(`Unknown units: ${convertFrom}`);
    }
    const defaultUnit = dimension.units.find(unit => unit.id === dimension.defaultUnit);
    const sourceUnit = dimension.units.find(unit => unit.id === convertFrom);
    if (!defaultUnit || !sourceUnit) {
        return null;
    }
    if (convertFrom === defaultUnit.id) {
        return value;
    }
    return convertBetweenUnits(value, sourceUnit, defaultUnit);
}
export function convertBetweenUnits(value, sourceUnit, targetUnit) {
    if (!numberIsFinite(value)) {
        return value;
    }
    const baseValue = (value - sourceUnit.Offset) / sourceUnit.Scale;
    const convertedValue = (baseValue * targetUnit.Scale) + targetUnit.Offset;
    return convertedValue;
}
