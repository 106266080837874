import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Component, Children, createRef } from 'react';
import { Icons } from '@zaber/react-library';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectRouterLocation } from './selectors';
import { NavigationChildType } from './types';
class NavigationGroupBase extends Component {
    constructor(props) {
        super(props);
        this.containerRef = createRef();
        this.expand = () => this.setState({ expanded: true });
        this.collapse = () => this.setState({ expanded: false });
        this.state = {
            expanded: false,
        };
    }
    getTitleElement() {
        var _a, _b;
        const { title, useOnlyLinkAsTitle, children } = this.props;
        const titleClass = classNames('group-title', { highlighted: this.pageUrlBelongsInThisGroup() });
        const canExpand = this.canExpand();
        if (this.getChildTypeCount(NavigationChildType.Link) === 1 && useOnlyLinkAsTitle) {
            const child = this.getFirstChildToMatch(children, child => this.getChildType(child) === NavigationChildType.Link);
            const path = (_a = child === null || child === void 0 ? void 0 : child.props) === null || _a === void 0 ? void 0 : _a.path;
            return _jsx(Link, { className: titleClass, to: path, children: child });
        }
        const titleChild = this.getFirstChildToMatch(children, child => this.getChildType(child) === NavigationChildType.Title);
        return _jsxs("div", { className: titleClass, onClick: canExpand ? this.expand : undefined, children: [(_b = titleChild !== null && titleChild !== void 0 ? titleChild : title) !== null && _b !== void 0 ? _b : 'Missing Title', this.canExpand() && _jsx(Icons.DropdownArrow, { className: "dropdown-icon", title: "Dropdown icon" })] });
    }
    getChildType(child) {
        var _a;
        return (_a = child === null || child === void 0 ? void 0 : child.type) === null || _a === void 0 ? void 0 : _a.type;
    }
    getChildTypeCount(type) {
        const { children } = this.props;
        let count = 0;
        Children.forEach(children, child => {
            if (this.getChildType(child) === type) {
                count++;
            }
        });
        return count;
    }
    getFirstChildToMatch(children, match) {
        let childToReturn = null;
        Children.forEach(children, child => {
            if (childToReturn === null && match(child)) {
                childToReturn = child;
            }
        });
        return childToReturn;
    }
    canExpand() {
        const linkCountThreshold = this.props.useOnlyLinkAsTitle ? 1 : 0;
        const linkCount = this.getChildTypeCount(NavigationChildType.Link);
        const unrecognizedChildCount = this.getChildTypeCount();
        return linkCount > linkCountThreshold || unrecognizedChildCount > 0;
    }
    pageUrlBelongsInThisGroup() {
        const { children, location, pathPrefix } = this.props;
        if (pathPrefix) {
            return location.pathname.startsWith(pathPrefix);
        }
        return this.getFirstChildToMatch(children, child => {
            var _a;
            const childPath = (_a = child === null || child === void 0 ? void 0 : child.props) === null || _a === void 0 ? void 0 : _a.path;
            return location.pathname.startsWith(childPath);
        }) != null;
    }
    renderChild(child) {
        var _a;
        const { location } = this.props;
        const childType = this.getChildType(child);
        if (!child || childType === NavigationChildType.Title) {
            return null;
        }
        if (childType === NavigationChildType.Separator) {
            return child;
        }
        if (childType === NavigationChildType.Link) {
            const childPath = (_a = child === null || child === void 0 ? void 0 : child.props) === null || _a === void 0 ? void 0 : _a.path;
            return _jsx(Link, { to: childPath, className: classNames('option', { highlighted: location.pathname.startsWith(childPath) }), onClick: () => this.setState({ expanded: false }), children: child });
        }
        return _jsx("div", { className: "option", children: child });
    }
    render() {
        const { children } = this.props;
        const { expanded } = this.state;
        const canExpand = this.canExpand();
        return (_jsxs("div", { className: "navigation-group", ref: this.containerRef, onMouseEnter: canExpand ? this.expand : undefined, onMouseLeave: canExpand ? this.collapse : undefined, children: [this.getTitleElement(), canExpand && _jsx("div", { className: classNames('options-container', { hidden: !expanded }), children: Children.map(children, child => this.renderChild(child)) })] }));
    }
}
export const NavigationGroup = connect((state) => ({
    location: selectRouterLocation(state),
}))(NavigationGroupBase);
