import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { makeString, throwIfJsError } from '@zaber/toolbox';
import { getMessageFromError, ZaberApi } from '../app_components';
import { getContainer } from '../container';
import { ActionTypes, actions } from './actions';
export function* userAccessTokenSaga() {
    yield all([
        takeLatest(ActionTypes.LOAD_ACCESS_TOKENS, loadAccessTokens),
        takeEvery(ActionTypes.CREATE_ACCESS_TOKEN, createAccessToken),
        takeEvery(ActionTypes.DELETE_ACCESS_TOKEN, deleteAccessToken),
    ]);
}
function* loadAccessTokens() {
    try {
        const api = getContainer().get(ZaberApi);
        const tokens = yield call([api, api.getUserAccessTokens]);
        yield put(actions.accessTokensLoaded(tokens));
    }
    catch (e) {
        throwIfJsError(e);
        yield put(actions.accessTokensLoadError(getMessageFromError(e)));
    }
}
function* createAccessToken({ payload: { description, expiryDate, scopes } }) {
    try {
        const data = {};
        if (description) {
            data.description = description;
        }
        if (scopes.length) {
            data.scopes = scopes;
        }
        if (expiryDate) {
            try {
                // The token will expire at the START of the date specified by user and will not be usable
                // from that day forward
                data.expiry = new Date(`${expiryDate} 00:00:00.000`).toISOString();
            }
            catch (e) {
                throwIfJsError(e);
            }
        }
        const api = getContainer().get(ZaberApi);
        const { tokenId, token } = yield call([api, api.createUserAccessToken], data);
        yield put(actions.accessTokenCreated(tokenId, token));
        yield put(actions.loadAccessTokens());
    }
    catch (e) {
        throwIfJsError(e);
        yield put(actions.accessTokenCreationError(getMessageFromError(e)));
    }
}
function* deleteAccessToken({ payload: { tokenId } }) {
    try {
        const api = getContainer().get(ZaberApi);
        yield call([api, api.deleteUserAccessToken], tokenId);
        yield put(actions.accessTokenDeleted(tokenId));
        yield put(actions.loadAccessTokens());
    }
    catch (e) {
        throwIfJsError(e);
        const message = Array.isArray(e) && e[0] instanceof Error ? e[0].message : undefined;
        yield put(actions.accessTokenDeletionError(tokenId, message || makeString(e)));
    }
}
