import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import _ from 'lodash';
import { Component } from 'react';
import classNames from 'classnames';
import Select from 'react-select';
import { Text, TextType } from '@zaber/react-library';
import { connect } from 'react-redux';
import { selectSimulationProducts, selectSimulationProductsLoading } from './selectors';
import { selectStyles } from './VirtualDeviceSelectTypes';
const FAMILY_DESCRIPTIONS = {
    LSM: 'Compact, Screw Drive',
    LRM: 'Compact, High Stiffness, Screw Drive',
    LSQ: 'Moderate Load, Screw Drive',
    LRQ: 'Moderate Load, High Stiffness, Screw Drive',
    LRT: 'High Load, Screw Drive',
    LHM: 'Light Duty, Compact, Screw Drive',
    LC40: 'Configurable, Belt Drive, T-Slot Profile',
    BLQ: 'Long Travel, Belt Drive',
    LDA: 'Compact, Linear Motor',
    LDM: 'Ultra Precision, Linear Motor',
    LDQ: 'Long Travel, Linear Motor',
    DMQ: 'Compact, High Speed Direct Drive',
    LSA: 'Ultra Compact, Screw Drive',
    ASR: 'Screw Drive',
    ADR: 'Linear Motor',
    RSB: 'High Speed, Belt Drive',
    RSM: 'Ultra Compact, Worm Gear Drive',
    RST: 'High Load, Worm Gear Drive',
    RSW: 'Compact, Worm Gear Drive',
    VSR: 'Telescoping, Screw Drive',
};
function getSeriesDescription(series) {
    const parts = series.split('-');
    if (!parts.length) {
        return undefined;
    }
    let integrated = false;
    if (parts[0] === 'X') {
        integrated = true;
        parts.shift();
    }
    const body = parts.length > 0 ? parts[0] : '';
    const flags = parts.length > 1 ? parts[1] : '';
    const options = [];
    if (flags.includes('V')) {
        options.push('Vacuum');
    }
    if (flags.includes('DE')) {
        options.push('Linear Quadrature Encoder');
    }
    else if (flags.includes('AE')) {
        options.push('Linear Analog Encoder');
    }
    else if (flags.includes('E')) {
        options.push('Motor Encoder');
    }
    if (flags.includes('C')) {
        options.push('Dust Cover');
    }
    if (flags.includes('B')) {
        options.push('Brake');
    }
    if (body.endsWith('L')) {
        options.push('Inline drive');
    }
    else if (body.endsWith('P')) {
        options.push('Parallel drive');
    }
    else if (body.startsWith('RSB')) {
        if (body.endsWith('D')) {
            options.push('Motor down');
        }
        else if (body.endsWith('U')) {
            options.push('Motor up');
        }
        else if (body.endsWith('B')) {
            options.push('Motor down');
            options.push('Long stack motor');
        }
        else if (body.endsWith('T')) {
            options.push('Motor up');
            options.push('Long stack motor');
        }
    }
    if (integrated) {
        options.push('Built-In Controller');
    }
    if (flags.includes('S')) {
        options.push('Without Manual Control');
    }
    if (options.length) {
        return options.join(', ');
    }
    return undefined;
}
export const SimulationProductLabel = ({ label, description }) => _jsxs("div", { className: "select-item-multiline", children: [_jsx(Text, { children: label }), description && _jsx(Text, { t: Text.Type.Helper, className: "description", children: description })] });
function ensureHasKey(dict, key, defaultValue) {
    if (!(key in dict)) {
        dict[key] = defaultValue;
    }
}
function categorize(name) {
    var _a, _b;
    const catNames = {
        L: 'Linear',
        B: 'Linear',
        D: 'Linear',
        A: 'X-Y',
        R: 'Rotary',
        V: 'Vertical',
    };
    const letter = (_a = name.match(/^X-([A-Z])/)) === null || _a === void 0 ? void 0 : _a[1];
    const category = (letter && letter in catNames) ? `${catNames[letter]} Stages` : 'Other';
    const fam = (_b = name.match(/^X-([A-Z]+)\d/)) === null || _b === void 0 ? void 0 : _b[1];
    let family = 'Other';
    let series = 'Other';
    if (fam) {
        family = `${fam} Family`;
        const parts = name.split('-');
        let letters = '';
        if (parts.length > 2) {
            letters = parts[2].split('').filter(char => /[A-Z]/.test(char)).join('');
            series = `X-${fam}-${letters} Series`;
        }
        else {
            series = `X-${fam} Series`;
        }
    }
    return {
        category,
        family,
        series,
    };
}
function buildProductTree(productNames) {
    const cats = {};
    for (const product of productNames) {
        const { category, family, series } = categorize(product);
        ensureHasKey(cats, category, {});
        const families = cats[category];
        ensureHasKey(families, family, {});
        const serieses = families[family];
        ensureHasKey(serieses, series, []);
        serieses[series] = _.uniq([...serieses[series], product]).sort();
    }
    return cats;
}
class VirtualDeviceProductSelectorBase extends Component {
    constructor(props) {
        super(props);
        this.state = {
            prevProducts: [],
            tree: {},
        };
    }
    static getDerivedStateFromProps(props, state) {
        var _a;
        const { simulationProducts, value } = props;
        const { category: defaultCategory, family: defaultFamily, series: defaultSeries } = ((_a = props.value) === null || _a === void 0 ? void 0 : _a.length) ? categorize(props.value) : { category: undefined, family: undefined, series: undefined };
        const productsChanged = !_.isEqual(simulationProducts !== null && simulationProducts !== void 0 ? simulationProducts : [], state.prevProducts);
        const tree = productsChanged ? buildProductTree(simulationProducts !== null && simulationProducts !== void 0 ? simulationProducts : []) : state.tree;
        const category = defaultCategory !== null && defaultCategory !== void 0 ? defaultCategory : _.maxBy(_.keys(tree), c => tree[c].length);
        const valueChanged = (value !== state.prevValue);
        const valueValid = (value !== null && value !== void 0 ? value : '').length > 0;
        return Object.assign(Object.assign({}, state), { prevProducts: simulationProducts !== null && simulationProducts !== void 0 ? simulationProducts : [], prevValue: value, tree, category: valueChanged && valueValid ? category : state.category, family: valueChanged && valueValid ? defaultFamily : state.family, series: valueChanged && valueValid ? defaultSeries : state.series, product: valueChanged && valueValid ? props.value : state.product });
    }
    render() {
        var _a, _b, _c, _d, _e;
        const { simulationProductsLoading, disabled, onValueChange, className } = this.props;
        const { tree, category, family, series, product } = this.state;
        const isLoading = (simulationProductsLoading === null || simulationProductsLoading === void 0 ? void 0 : simulationProductsLoading.state) === 'loading';
        const hasError = (simulationProductsLoading === null || simulationProductsLoading === void 0 ? void 0 : simulationProductsLoading.state) === 'error';
        const categoryOptions = _.keys(tree).sort().map(cat => ({
            value: cat,
            label: cat,
        }));
        const familyOptions = category ? _.keys(tree[category]).sort().map(fam => {
            const tipKey = fam.substring(0, fam.indexOf(' '));
            return {
                value: fam,
                label: fam,
                description: FAMILY_DESCRIPTIONS[tipKey],
            };
        }) : [];
        const seriesOptions = category && family ? _.keys((_b = (_a = tree[category]) === null || _a === void 0 ? void 0 : _a[family]) !== null && _b !== void 0 ? _b : {}).sort().map(ser => {
            const series = ser.substring(0, ser.indexOf(' '));
            return {
                value: ser,
                label: ser,
                description: getSeriesDescription(series),
            };
        }) : [];
        const productOptions = category && family && series ?
            ((_e = (_d = (_c = tree[category]) === null || _c === void 0 ? void 0 : _c[family]) === null || _d === void 0 ? void 0 : _d[series]) !== null && _e !== void 0 ? _e : []).map(prod => ({
                value: prod,
                label: prod,
            })) : [];
        return _jsxs("div", { className: "virtual-device-product-select", children: [_jsx(Text, { t: TextType.BodyXSm, e: Text.Emphasis.Light, children: "Category" }), _jsx(Text, { t: TextType.BodyXSm, e: Text.Emphasis.Light, children: "Family" }), _jsx(Text, { t: TextType.BodyXSm, e: Text.Emphasis.Light, children: "Series" }), _jsx(Text, { t: TextType.BodyXSm, e: Text.Emphasis.Light, children: "Product" }), _jsx(Select, { className: classNames('virtual-device-select', 'category-selector', className), classNamePrefix: "vd", isDisabled: isLoading || hasError || disabled, isOptionDisabled: option => option.disabled === true, placeholder: isLoading ? 'Loading...' : (hasError ? 'Error loading products' : 'Select a category'), onChange: option => {
                        if ((option === null || option === void 0 ? void 0 : option.value) !== category) {
                            this.setState({
                                category: option === null || option === void 0 ? void 0 : option.value,
                                family: undefined,
                                series: undefined,
                                product: undefined,
                            });
                            if (product) {
                                onValueChange === null || onValueChange === void 0 ? void 0 : onValueChange('');
                            }
                        }
                    }, "data-testid": "virtual-device-select-category", options: categoryOptions, formatOptionLabel: SimulationProductLabel, value: categoryOptions.find(option => option.value === category), styles: selectStyles }), _jsx(Select, { className: classNames('virtual-device-select', 'family-selector', className), classNamePrefix: "vd", isDisabled: isLoading || hasError || !!disabled || !category, isOptionDisabled: option => option.disabled === true, placeholder: isLoading ? 'Loading...' : (hasError ? 'Error loading products' : 'Select a family'), onChange: option => {
                        if ((option === null || option === void 0 ? void 0 : option.value) !== family) {
                            this.setState({
                                family: option === null || option === void 0 ? void 0 : option.value,
                                series: undefined,
                                product: undefined,
                            });
                            if (product) {
                                onValueChange === null || onValueChange === void 0 ? void 0 : onValueChange('');
                            }
                        }
                    }, "data-testid": "virtual-device-select-family", options: familyOptions, formatOptionLabel: SimulationProductLabel, value: familyOptions.find(option => option.value === family), styles: selectStyles }, `family-selector-${family}`), _jsx(Select, { className: classNames('virtual-device-select', 'series-selector', className), classNamePrefix: "vd", isDisabled: isLoading || hasError || !!disabled || !family, isOptionDisabled: option => option.disabled === true, placeholder: isLoading ? 'Loading...' : (hasError ? 'Error loading products' : 'Select a series'), onChange: option => {
                        if ((option === null || option === void 0 ? void 0 : option.value) !== series) {
                            this.setState({
                                series: option === null || option === void 0 ? void 0 : option.value,
                                product: undefined,
                            });
                            if (product) {
                                onValueChange === null || onValueChange === void 0 ? void 0 : onValueChange('');
                            }
                        }
                    }, "data-testid": "virtual-device-select-series", options: seriesOptions, formatOptionLabel: SimulationProductLabel, value: seriesOptions.find(option => option.value === series), styles: selectStyles }, `series-selector-${series}`), _jsx(Select, { className: classNames('virtual-device-select', 'product-selector', className), classNamePrefix: "vd", isDisabled: isLoading || hasError || !!disabled || !series, isOptionDisabled: option => option.disabled === true, placeholder: isLoading ? 'Loading...' : (hasError ? 'Error loading products' : 'Select a product'), onChange: option => {
                        var _a;
                        if ((option === null || option === void 0 ? void 0 : option.value) !== product) {
                            this.setState({
                                product: option === null || option === void 0 ? void 0 : option.value,
                            });
                            onValueChange === null || onValueChange === void 0 ? void 0 : onValueChange((_a = option === null || option === void 0 ? void 0 : option.value) !== null && _a !== void 0 ? _a : '');
                        }
                    }, "data-testid": "virtual-device-select", options: productOptions, formatOptionLabel: SimulationProductLabel, value: productOptions.find(option => option.value === product), styles: selectStyles }, `product-selector-${product}`)] });
    }
}
export const VirtualDeviceProductSelector = connect((state) => ({
    simulationProductsLoading: selectSimulationProductsLoading(state),
    simulationProducts: selectSimulationProducts(state),
}))(VirtualDeviceProductSelectorBase);
