import * as Sentry from '@sentry/react';
export const sentryBreadcrumb = () => next => action => {
    Sentry.addBreadcrumb({
        category: 'redux',
        data: {
            reduxAction: JSON.stringify(action),
            // serialization of such large object may affect performance, turn off for now
            // we can later only send changed part of the state
            // reduxState: JSON.stringify(api.getState()),
        }
    });
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return next(action);
};
