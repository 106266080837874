import { environment } from '../environment';
import { PageUrls } from '../urls';
import { TUTORIALS, METADATA } from './markdown/content_generated';
const videoBaseUrl = environment.build === 'production'
    ? 'https://software.zaber.com/video/tutorials'
    : 'https://cloud-staging.zaber.io/video/tutorials';
export function getVideoPosterUrl(videoName) {
    return `${videoBaseUrl}/${videoName}/${videoName}.png`;
}
export function getVideoUrl(videoName) {
    return `${videoBaseUrl}/${videoName}/${videoName}.mpd`;
}
export function getCaptionsUrl(videoName) {
    return `${videoBaseUrl}/${videoName}/${videoName}.srt`;
}
export function getChaptersUrl(videoName) {
    return `${videoBaseUrl}/${videoName}/${videoName}.vtt`;
}
export function getTutorialMetadata(tutorialKey) {
    return METADATA[tutorialKey];
}
export function getTutorialMarkdown(tutorialKey) {
    const content = TUTORIALS[tutorialKey];
    return content.slice(content.indexOf('---') + 3);
}
export function getTutorialUrl(tutorialKey) {
    return `${PageUrls.ZaberLauncherTutorials}/${tutorialKey}`;
}
