import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Units } from '@zaber/motion';
import { Text } from '@zaber/react-library';
import { useEffect, useState } from 'react';
import { convertFromDefaultUnits } from '../units';
import { UnitSelect } from './UnitSelect';
import { getDefaultDimensionUnits } from './utils';
const DECIMAL_PLACES = 2;
const DISPLAY_TEXT_FOR_INVALID_VALUE = `-.${'-'.repeat(DECIMAL_PLACES)}`;
export const ValueWithUnits = ({ valueInDefaultUnits, valueInNativeUnits, valueDimension }) => {
    var _a, _b;
    const [displayUnits, setDisplayUnits] = useState(Units.NATIVE);
    useEffect(() => {
        try {
            setDisplayUnits(valueDimension ? getDefaultDimensionUnits(valueDimension) : Units.NATIVE);
        }
        catch (_a) {
            setDisplayUnits(Units.NATIVE);
        }
    }, [valueDimension]);
    const value = displayUnits === Units.NATIVE
        ? valueInNativeUnits.toFixed(0)
        : (_b = (_a = convertFromDefaultUnits(valueInDefaultUnits, displayUnits)) === null || _a === void 0 ? void 0 : _a.toFixed(DECIMAL_PLACES)) !== null && _b !== void 0 ? _b : DISPLAY_TEXT_FOR_INVALID_VALUE;
    return (_jsxs("div", { className: "value-with-units", children: [_jsx(Text, { className: "position-text", children: value }), _jsx(UnitSelect, { value: displayUnits, dimension: valueDimension, onChange: newUnits => setDisplayUnits(newUnits) })] }));
};
